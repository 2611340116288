<h1 mat-dialog-title >Confirm Delete</h1>
<h2 mat-dialog-title >{{description}}</h2>
<h2 mat-dialog-title >APIs related to Subscription: {{apiNames.join(', ')}}</h2>

<mat-dialog-content [formGroup]="form">

    <mat-form-field appearance="fill" placeholder="Copy or type Subscription Name" value="">
        <input matInput placeholder="Confirm Subscription Name" formControlName="confirmDelete" required>      
            <mat-error
            *ngIf="(reason.touched || reason.dirty) && reason.invalid" >
        Required
        </mat-error>
  
    </mat-form-field> 
</mat-dialog-content>

<mat-dialog-actions>

    <button class="mat-raised-button"
            (click)="close()">
        Close
    </button>

    <button class="mat-raised-button mat-primary"
            (click)="confirmDelete()">
        Confirm Delete
    </button>

</mat-dialog-actions>