<mat-sidenav-container class="sidenav-container banner-container" fullscreen>
  <div>    
    <h1><img width="15px" alt="HIP Banner" src="assets/banner-img.png" /> &nbsp;
    Attention: This API Catalog v1.1 has been deprecated and replaced by a brand new <a [href]="this.apiCatalogUrl" target="_blank">API Catalog v2.0</a> offering you an enhanced experience, improved security and <a [href]="this.hipLatestFeatureDocumentUrl" target="_blank">newly added features.</a></h1>    
    <div>
      Both API Catalogs will co-exist until <B>August 31 2024.</B> After this date, the API Catalog v1.1 and all its Products/APIs/subscriptions will stop to exist.
      <ul>
        <li>
          If you are an <B>application owner with one or more published Products/APIs</B> in the HIP 1.1 platform, we strongly suggest you to plan and migrate.
        </li>
        <li>
          If you are a <B>Product/API subscriber</B>, you will have to resubscribe in HIP 2.0 and use the new credentials of each subscription in your application code.
        </li>
      </ul>
    </div>
    <p>
      The <B>final deadline</B> to have completed this migration (for both API publishers and subscribers) <B>is set to August 31 2024</B>.
      There is detailed <a [href]="this.planningDocumentationUrl" target="_blank">planning & approach</a> information and a <a [href]="this.documentationUrl" target="_blank">tutorial</a> available to assist you with migrating existing and/or onboarding new APIs. In case of any questions, please contact the <a [href]="this.hipTeamUrl" target="_blank">HIP Team</a>.
    </p>    
  </div>
</mat-sidenav-container>
<mat-sidenav-container class="sidenav-container" fullscreen style="top:180px">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true" [attr.role]="'navigation'" [mode]="'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <img width="40" alt="HIP Logo" src="assets/HIP_logo.png" />
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-nav-list>
      <mat-list-item [routerLink]="['/subscriptions']" [routerLinkActive]="['active']">
        <mat-icon matListIcon>card_membership</mat-icon>
        <a matLine>My Subscriptions</a>
      </mat-list-item>

      <mat-list-item [routerLink]="['/approvals']" [routerLinkActive]="['active']">
        <mat-icon matListIcon>fact_check</mat-icon>
        <a matLine>My Approvals</a>
      </mat-list-item>

      <mat-list-item [routerLink]="['/products']" [routerLinkActive]="['active']">
        <mat-icon matListIcon>topic</mat-icon>
        <a matLine>Products</a>
      </mat-list-item>

      <mat-list-item *ngIf="showMyAPIs" [routerLink]="['/myapis']" [routerLinkActive]="['active']">
        <mat-icon matListIcon>card_membership</mat-icon>
        <a matLine>My APIs</a>
      </mat-list-item>

      <mat-list-item [routerLink]="['/monitor']" [routerLinkActive]="['active']">
        <mat-icon matListIcon>assessment</mat-icon>
        <a matLine>Health Monitor</a>
      </mat-list-item>      
    
      <mat-divider></mat-divider>

      <div class="bottom-content">
        <mat-list-item (click)="OpenCookbook()">
          <mat-icon matListIcon>home</mat-icon>
          <a matLine>Cookbook</a>
        </mat-list-item>

        <mat-list-item *ngIf="showAdministratorMenus" mat-icon-button id="theme-selector"
          [mat-menu-trigger-for]="adminToolsMenu" i18n-matTooltip="@@Select-a-theme" matTooltip="Admin Tools" tabindex="-1">
          <mat-icon matListIcon>settings</mat-icon>
          <a matLine>Admin</a>
        </mat-list-item>
        <!-- <mat-list-item *ngIf="showAdministratorMenus" mat-icon-button id="theme-selector"
                  [mat-menu-trigger-for]="themeMenu" i18n-matTooltip="@@Select-a-theme" matTooltip="Developer options"
                  tabindex="-1">
                  <mat-icon matListIcon>format_color_fill</mat-icon>
                  <a matLine>Options</a>
                </mat-list-item> -->
        </div>
        </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>{{componentTitle}}</span>

        <span class="spacer"></span>

        <span class="rigth-toolbar-row">
          <div style="font-size: large">
            <mat-select [disabled]="disableEnvironmentSwitch()" [(value)]="selectedEnvironment" (selectionChange)="onEnvironmentSelection()">
              <mat-option [value]="env.name" *ngFor="let env of environments">{{env.display}} Environment</mat-option>
            </mat-select>
          </div>
          <div style="text-align: right">
            <!-- <button *ngIf="environment.shortName != 'd' && !loginDisplay" mat-raised-button (click)="login()">Login</button>
            <button *ngIf="environment.shortName != 'd' && loginDisplay" mat-raised-button color="accent"  (click)="logout()">Logout</button> -->
            <div>{{userName}}</div>
          </div>
        </span>
      </mat-toolbar-row>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>


<mat-menu #themeMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="simulations">Simulations</button>
  <button mat-menu-item [matMenuTriggerFor]="theme">Theme</button>
  <button mat-menu-item [matMenuTriggerFor]="adminTools">Tools</button>
</mat-menu>

<mat-menu #adminToolsMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="admin">Tools</button>
  <!-- <button mat-menu-item [matMenuTriggerFor]="appRegistrations">App Registrations</button>  -->
</mat-menu>

<mat-menu #admin="matMenu">
  <button mat-menu-item (click)="AdminApis()">Overview Apis and Subscriptions</button>
 
</mat-menu>


<mat-menu #simulations="matMenu">
  <button mat-menu-item (click)="SimulateRequestSubscription()">Request Subsription (simulates APIM DevPortal Subscribe
    Redirect</button>
</mat-menu>

<mat-menu #theme="matMenu">
  <mat-checkbox [checked]="isThemeDark" (click)="$event.stopPropagation()" (change)="toggleDarkness()"
    class="mat-menu-item theme-selector-is-dark" i18n="@@Dark">Dark
  </mat-checkbox>

  <mat-grid-list cols="2" class="theme-selectors-container">
    <mat-grid-tile *ngFor="let theme of themes">
      <button mat-button class="theme-selector" (click)="$event.stopPropagation(); setTheme(theme)">
        <div [attr.class]="theme + ' theme-primary center'">
          <!-- <div [attr.class]="theme + ' theme-accent'"></div>
          <div [attr.class]="theme + ' theme-warn'"></div> -->
          <mat-icon *ngIf="activeTheme===theme"class="center theme-check">check</mat-icon>
          <mat-icon *ngIf="activeTheme!=theme"class="center"></mat-icon>
        </div>
      </button>
    </mat-grid-tile>
  </mat-grid-list>


</mat-menu>

<mat-menu #adminTools="matMenu">

  <button mat-menu-item [routerLink]="['/products/create-product']" [routerLinkActive]="['active']"
    *ngIf="showAdministratorMenus">
    <mat-icon matListIcon>topic</mat-icon>
    <a matLine>Create Product</a>
  </button>

  <button mat-menu-item [routerLink]="['/upsert-api']" [routerLinkActive]="['active']" *ngIf="showAdministratorMenus">
    <mat-icon matListIcon>topic</mat-icon>
    <a matLine>Create Api</a>
  </button>

  <button mat-menu-item [routerLink]="['/apis']" [routerLinkActive]="['active']" *ngIf="showAdministratorMenus">
    <mat-icon matListIcon>topic</mat-icon>
    <a matLine>Apis</a>
  </button>
</mat-menu>
