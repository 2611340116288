import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-subscription-confirmation',
  templateUrl: './request-subscription-confirmation.component.html',
  styleUrls: ['./request-subscription-confirmation.component.css']
})
export class RequestSubscriptionConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
