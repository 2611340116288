<table mat-table class="mat-elevation-z8 detail-container" [dataSource]="mySubscriptions$ | async"
    *ngIf="mySubscriptions$ else loading">

    <!-- ProductName Column -->
    <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef>Product name</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
    </ng-container>

    <!-- Usage Notes Column -->
    <ng-container matColumnDef="usageNotes">
        <th mat-header-cell *matHeaderCellDef>Usage notes</th>
        <td mat-cell *matCellDef="let element"> {{element.usageNotes}} </td>
    </ng-container>

    <!-- costCenter Column -->
    <ng-container matColumnDef="costCenter">
        <th mat-header-cell *matHeaderCellDef>Cost center</th>
        <td mat-cell *matCellDef="let element"> {{element.costCenter}} </td>
    </ng-container>

    <!-- famCode Column -->
    <ng-container matColumnDef="famCode">
        <th mat-header-cell *matHeaderCellDef>Fam code</th>
        <td mat-cell *matCellDef="let element"> {{element.famCode}} </td>
    </ng-container>

    <!-- sponsorName Column -->
    <ng-container matColumnDef="sponsorName">
        <th mat-header-cell *matHeaderCellDef>Sponsor name</th>
        <td mat-cell *matCellDef="let element"> {{element.sponsorName}} </td>
    </ng-container>

    <!-- businessAreaCode Column -->
    <ng-container matColumnDef="businessAreaCode">
        <th mat-header-cell *matHeaderCellDef>Business area code</th>
        <td mat-cell *matCellDef="let element"> {{element.businessAreaCode}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <!-- Approvers Column -->
    <ng-container matColumnDef="approvers">
        <th mat-header-cell *matHeaderCellDef>Approver(s)</th>
        <td mat-cell *matCellDef="let element">
            <div *ngFor="let approver of element.approvers">
                {{approver}}
            </div>            
        </td>
    </ng-container>

    <!-- ActivatedDate Column -->
    <ng-container matColumnDef="activatedDate">
        <th mat-header-cell *matHeaderCellDef>Activated date</th>
        <td mat-cell *matCellDef="let element"> {{element.activatedDate}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="routeToSubscriptionDetails(row)">
    </tr>

</table>

<ng-template #loading>
    <mat-label>Loading Subscription's</mat-label>
    <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
<ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
  </ng-container>
