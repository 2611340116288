<mat-card>
  <mat-card-subtitle>
  The current scope of this health monitor is limited to the Production Environment only.
</mat-card-subtitle>
  <mat-card-content>
    <iframe src="/healthchecks-ui">
    </iframe>
    
  </mat-card-content>
</mat-card>

