import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/services/state/State.service';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';
import { ICreateNewProduct } from '../models/icreateProduct';
import { Environments } from '../../../types/Environments';
import { BusinessAreaCodes } from 'src/app/types/BusinessAreas';

@Component({
  selector: 'app-create-product',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class CreateProductComponent implements OnInit {
  environmentSelection: Environments;
  buttonDisAllowed: boolean = false;
  createProduct: ICreateNewProduct;
  subscriptions: Subscription[] = [];
  productForm: FormGroup;
  businessAreaCodes= BusinessAreaCodes;

  constructor(private fb: FormBuilder,
    private stateService: StateService,
    private hipManagementService: HipManagementService) {

    this.productForm = fb.group({
      environment: [{ value: null, disabled: true }, Validators.required],
      productName: [null, Validators.required],
      businessAreaCode: [null, Validators.required],
    });
  }

  ngOnInit(): void {

    this.stateService.environmentSelection$.subscribe(
      (environment: Environments) => {
        this.environmentSelection = environment;

        this.createProduct = {
          environment: this.environmentSelection,
          productName: '',
          businessAreaCode: null
        };
        this.productForm.setValue(this.createProduct);
      }
    );

    this.createProduct = {
      environment: this.environmentSelection,
      productName: null,
      businessAreaCode: null
    };

    this.productForm.setValue(this.createProduct);
  }

  requestNewProduct(): void {
    if (this.productForm.valid && this.buttonDisAllowed === false) {
      this.buttonDisAllowed = true;
      this.createProduct = this.productForm.getRawValue();
      this.hipManagementService.CreateProduct(this.createProduct).subscribe(r => {
        // toDo
        this.buttonDisAllowed = false;
        this.createProduct = {
          environment: this.environmentSelection,
          productName: '',
          businessAreaCode: ''
        };
        this.productForm.setValue(this.createProduct);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
