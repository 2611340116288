<table mat-table class="mat-elevation-z8 detail-container" [dataSource]="appRoleAssignment$ | async"
    *ngIf="appRoleAssignment$ else loading">

    <!-- ApproleAssignmentId Column -->
    <!-- <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ApproleAssignmentId</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container> -->

    <!-- principalDisplayName Column -->
    <ng-container matColumnDef="principalDisplayName">
        <th mat-header-cell *matHeaderCellDef>PrincipalDisplayName</th>
        <td mat-cell *matCellDef="let element"> {{element.principalDisplayName}} </td>
    </ng-container>

    <!-- resourceId Column -->
    <ng-container matColumnDef="resourceId">
        <th mat-header-cell *matHeaderCellDef>Service Principal Proxy</th>
        <td mat-cell *matCellDef="let element"> {{element.resourceId}} </td>
    </ng-container>

     <!-- resourceDisplayName Column -->
     <ng-container matColumnDef="resourceDisplayName">
        <th mat-header-cell *matHeaderCellDef>Proxy Name</th>
        <td mat-cell *matCellDef="let element"> {{element.resourceDisplayName}} </td>
    </ng-container>

    <!-- PrincipalId Column -->
    <ng-container matColumnDef="principalId">
        <th mat-header-cell *matHeaderCellDef>Service Principal Client</th>
        <td mat-cell *matCellDef="let element"> {{element.principalId}} </td>
    </ng-container>

    <!-- AppRoleId Column -->
    <ng-container matColumnDef="appRoleId">
        <th mat-header-cell *matHeaderCellDef>AppRoleId</th>
        <td mat-cell *matCellDef="let element"> {{element.appRoleId}} </td>
    </ng-container>

      <!-- AppRoleName Column -->
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef>AppRoleName</th>
        <td mat-cell *matCellDef="let element"> {{element.roleName}} </td>
    </ng-container>

    
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<ng-template #loading>
    <mat-label>Loading Subscription's</mat-label>
    <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
<ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
  </ng-container>
