<div class="apis-container">

    <div class="apis-search-bar">
      <mat-form-field>
        <mat-label>Filter by Business Area</mat-label>
        <mat-select #select [(value)]="selectedBusinessArea"
          (selectionChange)="businessAreaSelectionChanged($event.value)">
          <mat-option [value]="businessAreaCode.name" *ngFor="let businessAreaCode of businessAreaCodes">
            {{businessAreaCode.display}}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field [floatLabel]="'always'">
        <mat-label>Filter by Api</mat-label>
        <input matInput placeholder="Search Apis" #input [(ngModel)]="filterText">
      </mat-form-field>
    </div>
  
    <div class="table-container mat-elevation-z8" *ngIf="dataSource.apis$ else loading">
      <mat-table class="apis-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="name"
        matSortDirection="asc" matSortDisableClear multiTemplateDataRows>
  
        <ng-container matColumnDef="name">
  
          <mat-header-cell *matHeaderCellDef mat-sort-header>ApiName</mat-header-cell>
  
          <mat-cell *matCellDef="let api" >{{api.name}}</mat-cell>
  
        </ng-container>
  
        <ng-container matColumnDef="businessAreaCode">
  
          <mat-header-cell *matHeaderCellDef mat-sort-header>BusinessAreaCode</mat-header-cell>
  
          <mat-cell *matCellDef="let api">{{api.businessAreaCode}}</mat-cell>
  
        </ng-container>
  
        <ng-container matColumnDef="cmdbId">
  
          <mat-header-cell *matHeaderCellDef mat-sort-header>CMDB</mat-header-cell>
  
          <mat-cell *matCellDef="let api">{{api.cmdbId}}</mat-cell>
  
        </ng-container>
  
  
        <ng-container matColumnDef="requiresManualApproval">
  
          <mat-header-cell *matHeaderCellDef mat-sort-header>Approval</mat-header-cell>
  
          <mat-cell *matCellDef="let api">{{api.requiresManualApproval}}</mat-cell>
  
        </ng-container>
  
  
        <ng-container matColumnDef="urlSuffix">
  
          <mat-header-cell *matHeaderCellDef mat-sort-header>Url</mat-header-cell>
  
          <mat-cell *matCellDef="let api">{{api.urlSuffix}}</mat-cell>
  
        </ng-container>
  
        <ng-container matColumnDef="description">
  
          <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
  
          <mat-cell *matCellDef="let api">{{api.description}}</mat-cell>
  
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="routeToSubscriptions(row)"></mat-row>
  
  
      </mat-table>
      <ng-container *ngIf="loading$ | async">
        <div class="overlay"></div>
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
      </ng-container>
    </div>
    <mat-paginator [length]="apisCount" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
  
  </div>
  
  <ng-template #loading>
    <mat-label>Loading Products</mat-label>
    <mat-progress-bar mode="query"></mat-progress-bar>
  </ng-template>