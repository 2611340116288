// npm
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

// constants
import { environment } from 'src/environments/environment';
import { ApiConstants } from '../service-constants';
import { ApiUsageStatus } from 'src/app/models/api-usage-status.enum';

// models and interfaces
import { IHealt } from '../../models';
import { IApprovalRequest } from '../../ui/approvals/models/iapprovalRequest';
import { ICreateApiUsage } from 'src/app/models/icreateApiUsage';
import { IQueryApproversByEmail } from './query-approvers-by-email';
import { IQuerySubscriptionsByEmail } from './query-subscriptions-by-email';
import { IApiUsage } from '../../models/IApiUsage';
import { ISubscription } from '../../ui/subscriptions/models/isubscription';
// services
import { StateService } from '../state/State.service';
import { IQueryApiUsageForSubscribers } from './query-apiUsage-for-subscribers';
import { ICreateNewSubscription } from 'src/app/ui/subscriptions/models/icreateNewSubscription';
import { ISubscriptionCreatedResult } from 'src/app/ui/subscriptions/models/isubscriptionCreatedResult';
import { IQuerySubscriptionKeyById } from './query-subscriptionkey-by-id';
import { IHttpFileResponse, IQueryHttpFileByApim } from './query-httpfile-by-apim';
import { ISubscriptionKey } from 'src/app/models/isubscriptionKey';

import { map } from 'rxjs/internal/operators/';
import { IQueryApisByProductEnvironmentId } from './query-apis-by-productname';
import { IProductCreatedResult } from 'src/app/ui/products/models/iproductCreatedResult';
import { ICreateNewProduct } from 'src/app/ui/products/models/icreateProduct';
import { ICreateApi } from 'src/app/ui/api/models/icreateApi';
import { IApiCreatedResult } from 'src/app/ui/api/models/iapiCreatedResult';
import { IDeleteProduct } from '../../ui/products/models/ideleteProduct';
import { Api, ApisAndFilterCount } from '../../models/api';
import { IDeleteApi } from '../../ui/api/models/ideleteApi';
import { IQueryProductsByApiId } from './query-products-by-api';
import { Product, ProductsAndFileterCount } from '../../models/product';
import { ApiSpec } from '../../models/apiSpec';
import { IRejectSubscription } from 'src/app/ui/subscriptions/models/irejectSubscription';
import { IQuerySubscriptionsByApi } from './query-apis-by-subscription';
import { IQueryApprolesByServicePrincipal } from './query-approles-by-serviceprincipal';
import { IAppRoleAssignment } from 'src/app/ui/appRegistrations/models/iappRoleAssignment';
import { ISubscriptiondata } from '../../ui/subscriptions/models/isubscriptionData';
import { CmdbDetails } from 'src/app/models/cmdb-details';
import { ISubscriptionTracing } from 'src/app/ui/subscriptions/models/isubscriptionTracing';

@Injectable({
  providedIn: 'root'
})
export class HipManagementService {
  // internal
  private subscriptions: Array<Subscription> = [];

  // constructor
  constructor(private http: HttpClient, private stateService: StateService) {
  }

  // implementations
  registerUserInApim(env= 'DEV') {
    return this.http.get<number>(this.getUrl(ApiConstants.API_GetTotalProducts), {
      params: new HttpParams()
          .set('environment', env)
        }).pipe(
          map(res => res)
      ).toPromise();
  }

  /* #region  Products */
  findProducts(env = 'DEV', businessAreaCode, filter = '', sortOrder = 'asc', pageNumber = 0,
               pageSize = 50): Observable<ProductsAndFileterCount> {

    return this.http.get<ProductsAndFileterCount>(this.getUrl(ApiConstants.API_GetProducts), {
      params: new HttpParams()
        .set('environment', env)
        .set('businessAreaCode', businessAreaCode)
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => res)
    );
  }
getDetailsByCmdbId(cmdbid:string): Observable<CmdbDetails>{
  return this.http.get<CmdbDetails>(this.getUrl(ApiConstants.API_GetDetailsByCmdbId), {
    params: new HttpParams()
      .set('cmdbid', cmdbid)
  }).pipe(
    map(res => res)
  );
}
  getTotalProducts(env: string) {
    return this.http.get<number>(this.getUrl(ApiConstants.API_GetTotalProducts), {
        params: new HttpParams()
            .set('environment', env)
          }).pipe(
            map(res => res)
        ).toPromise();
  }

  deleteProduct(product: IDeleteProduct)
  {
    let httpParams = new HttpParams()
    .set('productId', product.productId)
    .set('isDeletedBy', product.isDeletedBy);
    let options = { params: httpParams };
    return this.http.delete<IDeleteProduct>(this.getUrl(ApiConstants.API_DeleteProduct), options);
  }


  /* #region  Apis */
  findApis(env = 'DEV', businessAreaCode, filter = '', sortOrder = 'asc', pageNumber = 0,
           pageSize = 50): Observable<ApisAndFilterCount> {

    return this.http.get<ApisAndFilterCount>(this.getUrl(ApiConstants.API_GetApis), {
      params: new HttpParams()
        .set('environment', env)
        .set('businessAreaCode', businessAreaCode)
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => res)
    );
  }

  findMyApis(env = 'DEV', businessAreaCode, filter = '', sortOrder = 'asc', pageNumber = 0,
           pageSize = 50): Observable<ApisAndFilterCount> {

    return this.http.get<ApisAndFilterCount>(this.getUrl(ApiConstants.API_GetMyApis), {
      params: new HttpParams()
        .set('environment', env)
        .set('businessAreaCode', businessAreaCode)
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('email', this.stateService.EmailAddress)
    }).pipe(
      map(res => res)
    );
  }

  getTotalApis(env: string) {
    return this.http.get<number>(this.getUrl(ApiConstants.API_GetTotalApis), {
      params: new HttpParams()
        .set('environment', env)
    }).pipe(
      map(res => res)
    ).toPromise();
  }

  deleteApi(api: IDeleteApi) {
    let httpParams = new HttpParams()
      .set('apiId', api.apiId)
      .set('isDeletedBy', api.isDeletedBy);
    let options = { params: httpParams };
    return this.http.delete<IDeleteApi>(this.getUrl(ApiConstants.API_DeleteApi), options);
  }

  getApiContract(env: string, apimApiName: string): Observable<string> {
    return this.http.get<string>(this.getUrl(ApiConstants.API_GetApiSpec), {

      params: new HttpParams()
        .set('environment', env)
        .set('apiName', apimApiName)
    }).pipe(
      map(res => res)
    );
  }

  /* #endregion */
  /* #region  Subscriptions */
  deleteSubscription(subscriptionId: string, activeSubscription: boolean): Observable<any> {
    const httpParams = new HttpParams()
      .set('subscriptionId', subscriptionId)
      .set('deletedBy', this.stateService.EmailAddress)
      .set('activeSubscription', activeSubscription.toString());

    const options = { params: httpParams };
    return this.http.delete<any>(this.getUrl(ApiConstants.API_DeleteSubscription), options);
  }

  rejectSubscription(subscription: IRejectSubscription): Observable<any> {
    return this.http.put<any>(this.getUrl(ApiConstants.API_RejectSubscription), subscription);
  }

  enableTracingBySubscription(subscription: ISubscriptionTracing): Observable<any> {
    return this.http.patch<any>(this.getUrl(ApiConstants.API_Tracing), subscription);
  }

  GetSubscriptionsByEmail(env: string): Observable<ISubscription[]> {
    const querySubscriptionsByEmail: IQuerySubscriptionsByEmail = {
      Email: this.stateService.EmailAddress,
      Environment: env
    };

    return this.http.get<ISubscription[]>(this.getUrl(ApiConstants.API_GetSubscriptionsByEmail), { params: querySubscriptionsByEmail });
  }

  GetAssignedAppRoles(clientServicePrincipalId: string): Observable<IAppRoleAssignment[]> {
    const queryApprolesByServicePrincipal: IQueryApprolesByServicePrincipal = {
      ClientServicePrincipalId: clientServicePrincipalId
    };

    return this.http.get<IAppRoleAssignment[]>(
      this.getUrl(ApiConstants.API_GetAssignedAppRoles), { params: queryApprolesByServicePrincipal });
  }

  GetSubscriptionsForApi(env: string, apiId: string): Observable<ISubscription[]> {
    const querySubscriptionsByApi: IQuerySubscriptionsByApi = {
      ApiId: apiId,
      Environment: env
    };

    return this.http.get<ISubscription[]>(this.getUrl(ApiConstants.API_GetSubscriptionsForApi), { params: querySubscriptionsByApi });
  }

  GetSubscriptionsForMyApi(env: string, myApiId: string): Observable<ISubscription[]> {
    const querySubscriptionsByApi: IQuerySubscriptionsByApi = {
      ApiId: myApiId,
      Environment: env
    };

    return this.http.get<ISubscription[]>(this.getUrl(ApiConstants.API_GetSubscriptionsForMyApi), { params: querySubscriptionsByApi });
  }

  GetSubscriptionsListForApi(env: string, apiId: string): Observable<ISubscriptiondata[]> {
    const querySubscriptionsByApi: IQuerySubscriptionsByApi = {
      ApiId: apiId,
      Environment: env
    };

    return this.http.get<ISubscriptiondata[]>(this.getUrl(ApiConstants.API_GetSubscriptionsListForApi), { params: querySubscriptionsByApi });
  }

  GetSubscriptionsListForMyApi(env: string, myApiId: string): Observable<ISubscriptiondata[]> {
    const querySubscriptionsByApi: IQuerySubscriptionsByApi = {
      ApiId: myApiId,
      Environment: env
    };

    return this.http.get<ISubscriptiondata[]>(this.getUrl(ApiConstants.API_GetSubscriptionsListForMyApi), { params: querySubscriptionsByApi });
  }

  GetSubscriptionKey(subscriptionId: string): Observable<ISubscriptionKey> {
    const querySubscriptionKeyById: IQuerySubscriptionKeyById = {
      SubscriptionId: subscriptionId
    };
    return this.http.get<ISubscriptionKey>(this.getUrl(ApiConstants.API_GetSubscriptionKey), { params: querySubscriptionKeyById });
  }

  GetApiNames(subscriptionId: string): Observable<Api[]> {
    const querySubscriptionKeyById: IQuerySubscriptionKeyById = {
      SubscriptionId: subscriptionId
    };
    return this.http.get<Api[]>(this.getUrl(ApiConstants.API_GetApisForSubscription), { params: querySubscriptionKeyById });
  }

  GetHttpFile(env: string, apimApiName: string, subscriptionId: string, proxyClientId: string): Observable<IHttpFileResponse> {
    const queryHttpFileByApim: IQueryHttpFileByApim = {
      Environment: env,
      ApimApiName: apimApiName,
      SubscriptionId: subscriptionId,
      ProxyClientId: proxyClientId
    };
    //var httpFileResponse: IHttpFileStream | IHttpFileErrorResponse;

    // return this.http.get(this.getUrl(ApiConstants.API_GetHttpFileForSubscription), { params: queryHttpFileByApim,responseType:"text" })
    // .pipe(map((res: string | IHttpFileErrorResponse) => {
    //   if('errorCode' in res) {
    //     httpFileResponse = res;  
    //     console.log(httpFileResponse.errorCode);
    //     return res;
    //  } else {
    //       httpFileResponse = res;
    //       return res.responseData;
    //  }
    // }));    
    return this.http.get<IHttpFileResponse>(this.getUrl(ApiConstants.API_GetHttpFileForSubscription), 
    { params: queryHttpFileByApim });    
  }

  CreateSubscription(subscription: ICreateNewSubscription): Observable<ISubscriptionCreatedResult> {
    return this.http.post<ISubscriptionCreatedResult>(this.getUrl(ApiConstants.API_Subscribe), subscription);
  }


  CreateProduct(product: ICreateNewProduct): Observable<IProductCreatedResult> {
    return this.http.post<IProductCreatedResult>(this.getUrl(ApiConstants.API_CreateProduct),
    {
      environments: [product.environment],
      productName: product.productName,
      businessAreaCode: product.businessAreaCode
    });
  }

  CreateApi(api: ICreateApi): Observable<IApiCreatedResult> {
    return this.http.post<IApiCreatedResult>(this.getUrl(ApiConstants.API_CreateApi), api);
  }

  /* #endregion */

  /* #region  Api Usage */
  GetApiUsageForApprovers(env: string): Observable<IApiUsage[]> {
    const queryApproversByEmail: IQueryApproversByEmail = {
      Email: this.stateService.EmailAddress,
      Environment: env
    };

    return this.http.get<IApiUsage[]>(this.getUrl(ApiConstants.API_GetApiUsageForApproversByEmail), { params: queryApproversByEmail });
  }

  GetApisByProductEnvironment(productEnvironmentId: string): Observable<Api[]> {
    const queryApisByProductEnvironmentId: IQueryApisByProductEnvironmentId = {
      ProductEnvironmentId: productEnvironmentId
    };

    return this.http.get<Api[]>(this.getUrl(ApiConstants.API_GetApisByProductEnvironmentId),
    { params: queryApisByProductEnvironmentId });
  }

  GetProductsForApi( apiId: string): Observable<Product[]> {
    const queryProductsByApi: IQueryProductsByApiId = {
      ApiId: apiId
    };

    return this.http.get<Product[]>(this.getUrl(ApiConstants.API_GetProductsByApi), { params: queryProductsByApi });
  }

  GetApiUsageForSubscribers(subscriptionId: string): Observable<IApiUsage[]> {
    const queryApiUsageBySubscriptionId: IQueryApiUsageForSubscribers = {
      SubscriptionId: subscriptionId
    };

    return this.http.get<IApiUsage[]>(this.getUrl(ApiConstants.API_GetApiUsageBySubscriptionId), { params: queryApiUsageBySubscriptionId });
  }

  CreateApiUsage(apiUsage: IApiUsage, remarks: string, appRoleIds: string[], subscriptionAttributeValues: {[key: string]: string}): 
  Observable<IApprovalRequest> {
    const createApiUsage = {
      appRoleIds,
      apiId: apiUsage.api.id,
      subscriptionId: apiUsage.subscription.id,
      status: ApiUsageStatus.ACTIVE,
      remarks,
      subscriptionAttributeValues
    } as ICreateApiUsage;

    return this.http.post<ICreateApiUsage>(this.getUrl(ApiConstants.API_ApiUsage), createApiUsage);
  }

  GetApiContract(env: string, apimApiName: string): Observable<ApiSpec[]> {
    return this.http.get<ApiSpec[]>(this.getUrl(ApiConstants.API_GetApiSpec), {
      params: new HttpParams()
        .set('environment', env)
        .set('apiName', apimApiName)
    }).pipe(
      map(res => res)
    );
  }
  /* #endregion */

  /* #region  Health */
  resolveHealth(): Observable<IHealt> {
    return this.http.get<IHealt>(this.getUrl(ApiConstants.API_Health));
  }
  /* #endregion */

  /* #region  Helpers */

  private getUrl(relativePath: string) {
    return `${environment.managementHIPUri}${relativePath}`;
  }

  // clean up
  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
  /* #endregion */
}
