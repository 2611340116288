import { Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, AbstractControl} from '@angular/forms';
import { RejectSubscription } from './rejectSubscription';

@Component({
    selector: 'app-reject-dialog',
    templateUrl: './reject-dialog.component.html',
    styleUrls: ['./reject-dialog.component.css']
})
export class RejectDialogComponent implements OnInit {

    form: FormGroup;
    reasonReject: string;
    appId: string;
    description: string;
    apiNames: string[];

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<RejectDialogComponent>,
        @Inject ( MAT_DIALOG_DATA ) { subscription, apiNames }: RejectSubscription ) {

        this.description = 'This action will Reject the following Subscription : [' + subscription + ']. \nThis action cannot be undone!';
        this.apiNames = apiNames;

        this.form = fb.group({
            reasonReject: [ '', Validators.required]
        });
    }

    ngOnInit() {

    }


    confirmReject() {
        if (this.form.valid){
            this.dialogRef.close(this.form.value);
        }
    }

    close() {
        this.dialogRef.close();
    }

    get reason(): AbstractControl {
        return this.form.get('reasonReject');
      }
}
