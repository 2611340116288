import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError, finalize } from 'rxjs/internal/operators';
import { Api, ApisAndFilterCount } from '../../models/api';
import { HipManagementService } from '../../services/hip-management/hip-management.service';

export class MyApisDataSource implements DataSource<Api> {

    private ApisSubject = new BehaviorSubject<Api[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public totalRecords: number;

    public apis$ = this.ApisSubject.asObservable();

    constructor(private hipManagementService: HipManagementService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Api[]> {
        return this.ApisSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.ApisSubject.complete();
        this.loadingSubject.complete();
    }

    loadMyApis(env = 'DEV', businessAreaCode = '', filter = '', sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
        this.loadingSubject.next(true);

        this.hipManagementService.findMyApis(env, businessAreaCode, filter, sortDirection,
            pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((apisAndCount: ApisAndFilterCount) => {
                this.totalRecords = apisAndCount.item2;
                this.ApisSubject.next(apisAndCount.item1);
            });
    }
}
