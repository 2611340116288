<h1 mat-dialog-title >Confirm Reject</h1>
<h2 mat-dialog-title >{{description}}</h2>
<h2 mat-dialog-title >APIs related to Subscription: {{apiNames.join(', ')}}</h2>
<h2 mat-dialog-title >All approval-requests for the underlying APIs of this Subscription will be Rejected. Please give a reason and confirm.</h2>


<mat-dialog-content [formGroup]="form">

    <mat-form-field appearance="fill" placeholder="Give a reason" value="">
        <input matInput placeholder="Reason Reject" formControlName="reasonReject" required>
      
            <mat-error
            *ngIf="(reason.touched || reason.dirty) && reason.invalid" >
        Required
        </mat-error>
  
    </mat-form-field> 
</mat-dialog-content>

<mat-dialog-actions>

    <button class="mat-raised-button"
            (click)="close()">
        Close
    </button>

    <button class="mat-raised-button mat-primary"
            (click)="confirmReject()">
        Confirm Reject
    </button>

</mat-dialog-actions>