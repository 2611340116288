<div class="details-container">

  <mat-grid-list cols="2" rowHeight="1.7:1" gutterSize="5px">
    <mat-grid-tile>
      <mat-card class="scrollable-content">
        <mat-card-header>
          <mat-card-title>{{cardTitle}}</mat-card-title>
          <mat-card-subtitle>{{cardSubTitle}}</mat-card-subtitle>
          <mat-card-subtitle>{{errorMessage}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="!requiresApproval">
            <form *ngIf="subscriptionKey else loading" [formGroup]="subscriptionSecretForm">
              <mat-form-field appearance="fill">
                <mat-label>Subscription name</mat-label>
                <input matInput formControlName="subscriptionName">
              </mat-form-field>
              <br>
              <mat-form-field appearance="fill">
                <mat-label>API key</mat-label>
                <input matInput formControlName="subscriptionSecret">
              </mat-form-field>
              <br>

              <div *ngIf="!noOauth && !viaMyapis">
                <mat-form-field appearance="fill">
                  <mat-label>ClientId</mat-label>
                  <input matInput formControlName="clientAppRegistrationId">
                </mat-form-field>
                <br>

                <mat-form-field appearance="fill">
                  <mat-label>DisplayName</mat-label>
                  <input matInput formControlName="clientAppRegistrationDisplayName">
                </mat-form-field>
                <br>

                <mat-form-field appearance="fill">
                  <mat-label>ClientSecret</mat-label>
                  <input matInput formControlName="clientAppRegistrationSecret">
                </mat-form-field>
              </div>
              <div *ngIf="!noOauth && viaMyapis">               
                <mat-form-field appearance="fill">
                  <mat-label>DisplayName</mat-label>
                  <input matInput formControlName="clientAppRegistrationDisplayName">
                </mat-form-field>                                
              </div>

              <div *ngIf="noOauth">
                <div [innerHTML]="noOauthMessage"></div>
              </div>

            </form>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-flat-button color="primary" (click)="Delete()">Delete</button>
          <button *ngIf="showTracing && !requiresApproval" mat-flat-button color="primary" style="float:right" (click)="enableTracing()">Enable Tracing</button>          
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="scrollable-content">
        <mat-card-content>
          <form [formGroup]="subscriptionUsageNotesForm">
            <mat-form-field appearance="fill">
              <mat-label>Usage notes</mat-label>
              <input matInput formControlName="usageNotes">
            </mat-form-field>
          </form>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">

      <div class="example-container ">
        <table mat-table *ngIf="apiUsages else loading" class="mat-elevation-z8" [dataSource]="apiUsages">

          <!-- Api Name Column -->
          <ng-container matColumnDef="apiName">
            <th mat-header-cell *matHeaderCellDef>API Name</th>
            <td mat-cell *matCellDef="let element"> {{element.api.name}} </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>

          <!-- ClientSecurity Proxy Column -->
          <ng-container matColumnDef="clientProxyId">
            <th mat-header-cell *matHeaderCellDef>ProxyClientId</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.clientProxyId; else defaultValue">
                {{element.clientProxyId}}
              </ng-container>
              <ng-template #defaultValue>
                No OAuth
              </ng-template>
            </td>
          </ng-container>

          <!-- Api Owner Column -->
          <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef>API Owner</th>
            <td mat-cell *matCellDef="let element"> {{element.owner}} </td>
          </ng-container>

          <!-- Security Column -->
          <ng-container matColumnDef="security">
            <th mat-header-cell *matHeaderCellDef>Security</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.clientProxyId; else defaultDisplay">
                OAuth
              </ng-container>
              <ng-template #defaultDisplay>
                No OAuth
              </ng-template>
            </td>
          </ng-container>

          <!-- Remarks Column -->
          <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef>Remarks</th>
            <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
          </ng-container>
          <!-- Download Httpfile Column -->
          <ng-container matColumnDef="httpfile">
            <th mat-header-cell *matHeaderCellDef>Http Test File</th>            
            <td mat-cell *matCellDef="let element"> 
              <button mat-flat-button color="primary"  style="margin-left: 5px;" (click)="downloadHttpFile(element.apimApiName, element.clientProxyId, element.api.name)">Download</button>  
            </td>
          </ng-container>


          <tr class="mat-caption" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr class="mat-display-1" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
        </table>
      </div>
    </mat-grid-tile>

  </mat-grid-list>  
</div>

<ng-template #loading>
  <mat-label>Loading details</mat-label>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</ng-container>