import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, merge } from 'rxjs';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/internal/operators';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { BusinessAreaCodes, BusinessAreas } from 'src/app/types/BusinessAreas';
import { Api } from '../../../models/api';
import { HipManagementService } from '../../../services/hip-management/hip-management.service';
import { StateService } from '../../../services/state/State.service';
import { Environments } from '../../../types/Environments';
import { LoadingService } from '../../loading.service';
import { ApisDataSource } from './api-datasource';

@Component({
  selector: 'app-apis',
  templateUrl: './apis.component.html',
  styleUrls: ['./apis.component.scss']
})
export class ApisComponent extends BaseComponent implements OnInit {
  apisCount: number;
  dataSource: ApisDataSource;
  businessAreaCodes = BusinessAreaCodes;
  loading$ = this.loader.loading$;

  selectedEnvironment: Environments;
  selectedBusinessAreaCode$ = new BehaviorSubject<BusinessAreas>('' as BusinessAreas);
  selectedBusinessArea = BusinessAreaCodes[0].name;
  filterText: string;

  displayedColumns = ['name', 'businessAreaCode', 'cmdbId', 'requiresManualApproval', 'urlSuffix', 'description'];
  init: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(
    private hipManagementService: HipManagementService,
    private stateService: StateService,
    public loader: LoadingService) {   super();  }

  ngOnInit() {
    this.stateService.setTitle("Apis");
    this.dataSource = new ApisDataSource(this.hipManagementService);

    this.selectedBusinessAreaCode$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (businessArea: BusinessAreas) => {
          this.selectedBusinessArea = businessArea;
        });

    this.stateService.environmentSelection$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (environment: Environments) => {
          this.selectedEnvironment = environment;
          this.filterText = "";
          this.selectedBusinessArea = "";
        }
      );

    this.dataSource.apis$
      .pipe(takeUntil(this.destroyed))
      .subscribe((p) => {
        this.apisCount = this.dataSource.totalRecords;
      });

    combineLatest([this.stateService.environmentSelection$, this.selectedBusinessAreaCode$])
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.dataSource.loadApis(this.selectedEnvironment, this.selectedBusinessArea, '', 'asc', 0, 10);
        this.apisCount = this.dataSource.totalRecords;
      },
        // Error => this.loader.hide(),
        // () => this.loader.hide()
      );
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadApisPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        tap(() => this.loadApisPage())
    )
    .subscribe();
    this.init = false;
  }

  loadApisPage() {
    this.dataSource.loadApis(
      this.selectedEnvironment,
      this.selectedBusinessArea,
      this.filterText,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  routeToProducts(api: Api) {
    this.stateService.routeToApiProducts(api);
  }

  businessAreaSelectionChanged(businessArea: BusinessAreas) {
    this.filterText = "";
    this.selectedBusinessAreaCode$.next(businessArea);
  }

  routeToSubscriptions(api: Api) {
    this.stateService.routeToSubscriptionsForApi(api);
  }

  routeToApiDetails(api: Api) {
    //this.stateService.routeToProductDetails(product, this.selectedEnvironment);
  }
}
