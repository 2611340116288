import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable()
export class HipManahementInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const apiKeyReq = req.clone({
            headers: req.headers.set(
                'apiKey',
                environment.apiKey
            )
        });
        req = apiKeyReq;

        return next.handle(req).pipe(
            tap(
                event => this.handleResponse(req, event),
                error => this.handleError(req, error)
            )
        );
    }

    handleResponse(req: HttpRequest<any>, event) {
        console.log('Handling response for ', req.url, event);
        if (event instanceof HttpResponse) {
            console.log('Request for ', req.url,
                ' Response Status ', event.status,
                ' With body ', event.body);
        }
    }

    handleError(req: HttpRequest<any>, event) {
        console.error('Request for ', req.url,
            ' Response Status ', event.status,
            ' With error ', event.error);
    }
}