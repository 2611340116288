<mat-card>
    <mat-card-header>
        <mat-card-subtitle>Approval for Environment {{environment}}</mat-card-subtitle>
        <mat-card-subtitle>Email Subscriber : {{apiUsage.subscription.user.userEmail}}</mat-card-subtitle>
        <mat-card-subtitle>ObjectId Subscriber : {{apiUsage.subscription.user.objectId}}</mat-card-subtitle>
        <mat-card-subtitle>Usage Notes : {{apiUsage.subscription.usageNotes}}</mat-card-subtitle>
        <mat-card-subtitle >You can add additional info in the remarks field before approval</mat-card-subtitle>
        <mat-card-subtitle>{{errorMessage}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="Approve()">
            <mat-form-field appearance="fill">
                <mat-label>Remarks</mat-label>
                <textarea matInput placeholder=" Provide a valid reason why this new subscription will still be appproved in HIP 1.1 platform" formControlName="remark" required title=""></textarea>
            </mat-form-field>
    
            <div *ngIf="hasRoles" >
            
                    <mat-label>This API is protected with OAuth and has following Role(s) defined: </mat-label>
                    <br>
                    <br> 
                    <label formArrayName="appRoles" *ngFor="let role of appRolesFormArray.controls; let i = index">
                        <input [attr.disabled]="rolesCheckBoxesDisabled ? true : null" type="checkbox" [formControlName]="i" />
                        {{appRoles[i].description}} - ( {{appRoles[i].displayName}} )
                        <br>
                    </label>
                    <br>
                    <mat-label>Please select at least 1 role. When the checkboxes are disabled this means that the underlying Client AppRegistration has already received a role in the past.</mat-label>
                    <br>
                <div *ngIf="!form.valid">At least one role must be selected</div>
            </div><br>
            <mat-divider></mat-divider>
            <br>
            <div *ngIf="hasSubscriptionAttributes">

                <mat-label>This API has the following Subscription Attribute(s) defined: </mat-label>
                <br>
                <br> 
                <label formArrayName="subscriptionAttributes" *ngFor="let claim of subscriptionAttributesFormArray.controls; let i = index">
                    {{subscriptionAttributes[i].displayName}}  - <input required placeholder="give a value" [formControlName]="i" name={{i}} value={{subscriptionAttributes[i].value}} size="70"/>                    
                    <br>
                </label>
           <br>
            <div *ngIf="!form.valid">All Subscription Attributes must have a value</div>
        </div>
        </form>
    
    </mat-card-content>
    <mat-card-actions >
        <button mat-flat-button color="primary" (click)="Approve()"  [disabled]="approveDisAllowed">Approve</button>
        <button mat-button (click)="Reject()">Reject</button>
        <button mat-button (click)="Cancel()">Cancel</button>
    </mat-card-actions>

    <mat-error *ngIf="validationError">
        <p>{{validationError}}</p>
    </mat-error>

    <!-- <div class="wrapper">
        <mat-progress-spinner mode="indeterminate" *ngIf="isWait" ></mat-progress-spinner>
    </div> -->

    <ng-container *ngIf="loading$ | async">
        <div class="overlay"></div>
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </ng-container>
</mat-card>
