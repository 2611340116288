import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { StateService } from '../../services/state/State.service';
import { Environments } from '../../types/Environments';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';

const THEME_DARKNESS_SUFFIX = `-dark`;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  hideMonitor = true;
  private readonly _destroying$ = new Subject<void>();

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  environments = [];

  selectedEnvironment: string;

  themes: string[] = [
    'deeppurple-amber',
    'indigo-pink',
    'pink-bluegrey',
    'purple-green',
  ];

  @HostBinding('class') activeThemeCssClass: string;
  isThemeDark = false;
  activeTheme: string;

  componentTitle: string;

  showAdministratorMenus: boolean = false;
  showMyAPIs: boolean = false;

  userName: string;
  apiCatalogUrl:string;
  documentationUrl: string;
  planningDocumentationUrl: string;
  hipTeamUrl:string;
  hipLatestFeatureDocumentUrl:string;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private breakpointObserver: BreakpointObserver,
    private stateService: StateService,
    private overlayContainer: OverlayContainer,
    private route: Router,
    @Inject(Window) private win: Window) {
    this.stateService.getTitle().subscribe(t => {
      this.componentTitle = t;
    });
    this.setTheme('indigo-pink', false); // Default Theme
    this.showAdministratorMenus = stateService.IsInRole("ApiCatalogAdmin");
    this.showMyAPIs = stateService.IsInRole("ApiOwners");
    this.userName = this.stateService.Account?.name;
    this.defineEnvironments();
    this.selectedEnvironment = this.environments[0].name;
    this.hideMonitor = environment.shortName == "d";
  }
  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.msalSubject$
    .pipe(
      // Optional filtering of events.
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS), 
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      this.checkAccount();
      this.userName = this.stateService.Account?.name;
    });    
    this.setCatalogLink();
  }

  setCatalogLink(){
    this.documentationUrl = "https://ac-its.atlassian.net/wiki/spaces/AHIP/pages/22216708/HIP+2.0+API+Onboarding+Migration";
    this.planningDocumentationUrl = "https://ac-its.atlassian.net/wiki/x/bQA9CQ";
    this.hipLatestFeatureDocumentUrl="https://ac-its.atlassian.net/wiki/spaces/AHIP/pages/22217559/What+s+new+in+HIP+2.0";
    this.hipTeamUrl = "https://onevirtualoffice.sharepoint.com/sites/hip";
    if(this.selectedEnvironment === 'DEV'){      
      this.apiCatalogUrl="https://apis-catalog-dev.atlascopco.com";
    }
    else if(this.selectedEnvironment === 'TST') {
      this.apiCatalogUrl="https://apis-catalog-tst.atlascopco.com";
    }
    else if(this.selectedEnvironment === 'PRD'){
      this.apiCatalogUrl="https://apis-catalog.atlascopco.com";
    }
    else {
      this.apiCatalogUrl="https://apis-catalog-dev.atlascopco.com"; 
    }
  }

  setTheme(theme: string, darkness: boolean = null) {
    if (darkness === null) {
      darkness = this.isThemeDark;
    }
    else if (this.isThemeDark === darkness) {
      if (this.activeTheme === theme) { return; }
    } else {
      this.isThemeDark = darkness;
    }

    this.activeTheme = theme;

    const cssClass = darkness === true ? theme + THEME_DARKNESS_SUFFIX : theme;

    const classList = this.overlayContainer.getContainerElement().classList;
    if (classList.contains(this.activeThemeCssClass)) {
      classList.replace(this.activeThemeCssClass, cssClass);
    }
    else {
      classList.add(cssClass);
    }

    this.activeThemeCssClass = cssClass;
  }

  toggleDarkness() {
    this.setTheme(this.activeTheme, !this.isThemeDark);
  }

  OpenAPIMDevPortal() {
    this.win.open('https://api-portal-dev.atlascopco.group/api-details');
  }

  OpenCookbook() {
    this.win.open('https://ac-hip-cookbook.azurewebsites.net/');
  }

  SimulateRequestSubscription() {
    this.stateService.routeToProcessorComponent("requestSubscription");
  }

  AdminApis() {
    this.stateService.routeToAdminApisComponent();
  }

  AdminAppRegistrations() {
    /// this.stateService.routeToClientAppRegistrationComponent();
  }

  onEnvironmentSelection() {
    this.stateService.updateEnvironmentSelection(<Environments>this.selectedEnvironment);
    this.setCatalogLink();
  }

  disableEnvironmentSwitch(): boolean {
    if (this.route.url.indexOf('/request-subscription') > -1) {
      return true;
    }
    return false;
  }

  defineEnvironments() {
    console.log(`Define environments for '${environment.shortName}'`)
    switch (environment.shortName) {
      case 'd':
        this.environments = [{
          name: 'DEV',
          display: 'Development'
        }, {
          name: 'TST',
          display: 'Test'
        }, {
          name: 'PRD',
          display: 'Production'
        }];
        break;
      case 'dh':
        this.environments = [{
          name: 'internalDEV',
          display: 'Internal development!'
        }               
      ];
        break;
      case 'th':
        this.environments = [{
          name: 'internalTST',
          display: 'Internal Test!'
        }];
        break;
      default:
        throw new Error(`Environment is not implemented ${environment.shortName}`);
    }
  }

  checkAccount() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe(() => this.checkAccount());
      } else {
        this.authService.loginPopup()
          .subscribe(() => this.checkAccount());
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    this.authService.logout();
  }
}
