<div *ngIf="urls" style="margin-left: 10px;margin-top: 10px;font-family: Roboto, 'Helvetica Neue', sans-serif;">
    <div [innerHtml]="htmlToAdd"></div>
    <div style="padding-top:10px;" *ngIf="urls[0]['BaseUrl'] !== null && urls[0]['BaseUrl'] !== ''">      
      <mat-form-field style="width: 600px;font-size: 14px;">
        <mat-select [(value)]="defaultBaseUrl" matNativeControl placeholder="Base URL(s)">
          <mat-option value="internal">{{urls[0]['BaseUrl']}}</mat-option>
          <mat-option *ngIf="urls[0]['IsExternal']" value="external">{{urls[0]['BaseUrl'].replace('.group', '.com')}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>     
</div>    
<ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</ng-container>
<div id="swagger-ui" style="margin-top: -30px;"></div>
