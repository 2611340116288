<div class="subscription-create">
    <h1>{{title}}</h1>    

    <div class="form" [formGroup]="form" (ngSubmit)="onSubmit()">

         <div *ngIf="form.invalid && form.errors"
             class="alert alert-danger">          
             
        </div> 
      
        <div class="form-group">
            <label for="name">Environment:</label>
            <br />
            <input type="text" id="environment"
                formControlName="environment" required
                placeholder="Enviontment..."
                class="form-control"
                />

            <div *ngIf="form.get('environment').invalid &&
                 (form.get('environment').dirty || form.get('environment').touched)"
                 class="invalid-feedback">
                <div *ngIf="form.get('environment').errors?.required">
                  Name is required.
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="name">Product:</label>
            <br />
            <input type="text" id="productName"
                formControlName="productName" required
                placeholder="Product..."
                class="form-control"
                />

            <div *ngIf="form.get('productName').invalid &&
                 (form.get('productName').dirty || form.get('productName').touched)"
                 class="invalid-feedback">
                <div *ngIf="form.get('productName').errors?.required">
                  Name is required.
                </div>
            </div>
        </div>


        <div class="form-group">
            <label for="name">Requester :</label>
            <br />
            <input type="text" id="requester"
                formControlName="requester" required
                placeholder="Requester..."
                class="form-control"
                />

            <div *ngIf="form.get('requester').invalid &&
                 (form.get('requester').dirty || form.get('requester').touched)"
                 class="invalid-feedback">
                <div *ngIf="form.get('requester').errors?.required">
                  Name is required.
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="name">CostCenter :</label>
            <br />
            <input type="text" id="costCenter"
                formControlName="costCenter" required
                placeholder="CostCenter..."
                class="form-control"
                />

            <div *ngIf="form.get('costCenter').invalid &&
                 (form.get('costCenter').dirty || form.get('costCenter').touched)"
                 class="invalid-feedback">
                <div *ngIf="form.get('costCenter').errors?.required">
                  Name is required.
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="name">FamCode :</label>
            <br />
            <input type="text" id="famCode"
                formControlName="famCode" required
                placeholder="FamCode..."
                class="form-control"
                />

            <div *ngIf="form.get('famCode').invalid &&
                 (form.get('famCode').dirty || form.get('famCode').touched)"
                 class="invalid-feedback">
                <div *ngIf="form.get('famCode').errors?.required">
                  Name is required.
                </div>
            </div>
        </div>


        <div class="form-group">
            <label for="name">SponsorName :</label>
            <br />
            <input type="text" id="sponsorName"
                formControlName="sponsorName" 
                placeholder="SponsorName..."
                class="form-control"
                />

            <div>
                 class="invalid-feedback">
                <div *ngIf="form.get('sponsorName').errors?.maxLength">
                  Name is too long.
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="name">BusinessAreaCode :</label>
            <br />
            <input type="text" id="businessAreaCode"
                formControlName="businessAreaCode" required
                placeholder="BusinessAreaCode..."
                class="form-control"
                />

            <div *ngIf="form.get('businessAreaCode').invalid &&
                 (form.get('businessAreaCode').dirty || form.get('businessAreaCode').touched)"
                 class="invalid-feedback">
                <div *ngIf="form.get('businessAreaCode').errors?.required">
                  Name is required.
                </div>
            </div>
        </div>
      
     

        <div class="form-group commands">           
            <button type="submit"
                    (click)="onSubmit()"
                    [disabled]="form.invalid"
                    class="btn btn-success">
                Create
            </button>
            <button type="button"
                    [routerLink]="['/home']"
                    class="btn">
                Cancel
            </button>
        </div>
    </div>
</div>