import { Injectable } from '@angular/core';
import { Subscription } from "rxjs";
@Injectable()
export abstract class BaseService
{
    subscriptions: Subscription[] = [];
    constructor() {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
      }
}
