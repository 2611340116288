import { Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { BaseService } from '../../core/base-classes/base-service';
import { IApiUsage } from '../../models/IApiUsage';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProcessorRoutetypes } from '../../types/processorRoutes';
import { Environments } from 'src/app/types/Environments';
import { Roles } from 'src/app/types/Roles';
import { Product } from 'src/app/models/product';
import { Api } from '../../models/api';
import { environment } from 'src/environments/environment';
import { AccountInfo } from '@azure/msal-browser';



@Injectable({
  providedIn: 'root'
})
export class StateService extends BaseService {



  // State objects
  private _apiUsage: IApiUsage;
  public get apiUsage(): IApiUsage {
    return this._apiUsage;
  }

  private _apimApiName: string;
  public get apimApiName(): string {
    return this._apimApiName;
  }

  private _subscriptionName: string;
  public get subscriptionName(): string {
    return this._subscriptionName;
  }

  private _api: Api;
  public get api(): Api {
    return this._api;
  }

  private _myApi: Api;
  public get myApi(): Api {
    return this._myApi;
  }

  private _apiId: string;
  public get apiId(): string {
    return this._apiId;
  }

  private _subscriptionId: string;
  public get subscriptionId(): string {
    return this._subscriptionId;
  }

  private _environment: Environments;
  public get Environment(): Environments {
    return this._environment;
  }

  private _productName: string;
  public get productName(): string {
    return this._productName;
  }

  private _productEnvironmentId: string;
  public get productEnvironmentId(): string {
    return this._productEnvironmentId;
  }


  //end state objects

  private title = new BehaviorSubject<string>('Api Catalog');
  private title$ = this.title.asObservable();

  private environmentSelection = new BehaviorSubject<Environments>(<Environments>environment.environment);
  public environmentSelection$ = this.environmentSelection.asObservable();

  constructor(
    private broadcastService: MsalBroadcastService,
    private authService: MsalService, private router: Router) {
    super();
    this.subscriptions.push(this.broadcastService.msalSubject$.subscribe(payload => {
      // do something intresting
    }));

    this._environment = <Environments>'DEV';
  }


  get Account(): AccountInfo {
    var activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount == null) {
      this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
      activeAccount = this.authService.instance.getActiveAccount();
    }
    return activeAccount;
  }

  get EmailAddress(): string {
    return this.Account?.username;
  }

  get Roles(): string[] {
    return [...this.Account?.idTokenClaims ? this.Account?.idTokenClaims["roles"] : ''];
  }

  IsInRole(role: Roles): boolean {
    let r = this.Roles;
    return (this.Roles.indexOf(role) > -1);
  }

  setTitle(title: string) {
    this.title.next(`API Catalog - ${title}`);
  }

  getTitle(): Observable<string> {
    return this.title$;
  }

  routeToHome() {
    this.router.navigate(['/']);
  }

  routetoApis() {
    this.router.navigate(['/apis']);
  }


  routeToApprovals() {
    this.router.navigate(['/approvals']);
  }

  routeToApprovalsByEnvionment(environment: Environments) {
    this._environment = environment;
    this.router.navigate(['/approvals']);
  }

  routeToApprovalDetails(apiUsage: IApiUsage) {
    this._apiUsage = apiUsage;
    this.router.navigate(['/approvaldetail']);
  }

  routeToSubscriptions() {
    this.router.navigate(['/subscriptions']);
  }

  routeToSubscriptionDetails(subscriptionId: string, productName: string) {
    this._subscriptionId = subscriptionId;
    this.router.navigate([`/subscriptions/${productName}/subscription/${subscriptionId}`]);
  }

  routeToRequestSubscription(productEnvironmentId: string, productName: string, environment: Environments) {
    this._productEnvironmentId = productEnvironmentId;
    this._productName = productName;
    this._environment = environment;
    this.router.navigate([`/products/${productName}/request-subscription/${productEnvironmentId}`]);
  }

  routeToAdminApisComponent() {
    this.router.navigate([`/apis`]);
  }

  routeToSubscriptionsForApi(api: Api) {
    this._api = api;
    this.router.navigate([`/apis/${api.id}/subscriptions`]);
  }

  routeToSubscriptionsForMyApi(myapi: Api) {
    this._myApi = myapi;
    this.router.navigate([`/myapis/${myapi.id}/subscriptions`]);
  }

  routeToClientAppRegistrationComponent(clientServicePrincipal: string, subscriptionName: string) {
    this._subscriptionName = subscriptionName;
    this.router.navigate([`/subscription/clientAppRegistration/${clientServicePrincipal}`]);
  }

  // Simulations
  routeToProcessorComponent(route: ProcessorRoutetypes) {
    this.router.navigate(['/email/processor'], { queryParams: { 'route': route, 'productName': 'test-oauth', 'environment': 'dev' } });
  }

  routeToProductDetails(product: Product, environment: Environments) {
    this._environment = environment;
    this._productName = product.name;
    this.router.navigate(['/product-api-detail']);
  }

  // routeToAdminApis() {
  //   this.router.navigate(['/apis']);
  // }

  routeToApiProducts(api: Api) {
    this._apiId = api.id;
    this.router.navigate(['/apiproducts']);
  }

  routeToSwagger(api: Api) {
    this._apimApiName = api.apimApiName;
    this.router.navigate([`/openapi/${api.apimApiName}`]);
  }

  updateEnvironmentSelection(newSelection: Environments) {
    this.environmentSelection.next(newSelection);
  }
}
