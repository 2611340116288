
<div>  
    <div style="margin-top:10px;margin-left:10px;">                                           
    <button mat-flat-button color="primary" (click)="ListofSubscription()">Export</button>
    </div>
    <div style="margin-top:10px;">    
    </div>
<table mat-table class="mat-elevation-z8 detail-container" [dataSource]="subscriptions$ | async"
    *ngIf="subscriptions$ else loading">

    <!-- ProductName Column -->
    <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef>Product name</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
    </ng-container>

    <!-- Usage Notes Column -->
    <ng-container matColumnDef="usageNotes">
        <th mat-header-cell *matHeaderCellDef>Usage notes</th>
        <td mat-cell *matCellDef="let element"> {{element.usageNotes}} </td>
    </ng-container>

    <!-- costCenter Column -->
    <ng-container matColumnDef="costCenter">
        <th mat-header-cell *matHeaderCellDef>Cost center</th>
        <td mat-cell *matCellDef="let element"> {{element.costCenter}} </td>
    </ng-container>

    <!-- famCode Column -->
    <ng-container matColumnDef="famCode">
        <th mat-header-cell *matHeaderCellDef>Fam code</th>
        <td mat-cell *matCellDef="let element"> {{element.famCode}} </td>
    </ng-container>

    <!-- sponsorName Column -->
    <ng-container matColumnDef="sponsorName">
        <th mat-header-cell *matHeaderCellDef>Sponsor name</th>
        <td mat-cell *matCellDef="let element"> {{element.sponsorName}} </td>
    </ng-container>

    <!-- businessAreaCode Column -->
    <ng-container matColumnDef="businessAreaCode">
        <th mat-header-cell *matHeaderCellDef>Business area code</th>
        <td mat-cell *matCellDef="let element"> {{element.businessAreaCode}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <!-- ActivatedDate Column -->
    <ng-container matColumnDef="activatedDate">
        <th mat-header-cell *matHeaderCellDef>Activated date</th>
        <td mat-cell *matCellDef="let element"> {{element.activatedDate}} </td>
    </ng-container>

    <!-- UserEmail Column -->
    <ng-container matColumnDef="userEmail">
        <th mat-header-cell *matHeaderCellDef>UserEmail</th>
        <td mat-cell *matCellDef="let element"> {{element.user.userEmail}} </td>
    </ng-container>

   
      <!-- SubscriptionName Column -->
      <ng-container matColumnDef="subscriptionName">
        <th mat-header-cell *matHeaderCellDef>SubscriptionDetail</th>
        <td mat-cell *matCellDef="let element"> 
            <button mat-button color="primary" (click)="routeToSubscriptionDetails(element)">Detail</button>
         </td>
    </ng-container>
      <!-- appRegClientIdAppBound Column -->
    <ng-container matColumnDef="clientAppRegistration">
        <th mat-header-cell *matHeaderCellDef>Client App Reg</th>
        <td mat-cell *matCellDef="let element">
            <div *ngIf="element.appRegClientIdUserBound || element.appRegClientIdAppBound">
                <button mat-button color="primary"
                    [textContent]="element.appRegClientIdUserBound ? 'UserBound' : 'AppBound'"
                    (click)="routeToClientAppRegistrationDetails(element)"></button>
            </div>
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
    </tr>

</table>

<ng-template #loading>
    <mat-label>Loading Subscription's</mat-label>
    <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
<ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
  </ng-container>

</div>
