import { Environments, EnvironmentShortNames } from '../app/types/Environments';
export const environment = {
  production: true,
  // will be set at runtime with the current root url
  redirectUri: () => `${ window.location.origin }/`,
  clientId: '06c8fff2-ad59-4f61-82a5-61fe36bc6658',
  authority: 'https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9',
  scopes: ["api://052cb2f2-a0a5-4a42-b53c-603bf423cdbf/access_as_user"],
  apiKey: 'ea130697-65d0-4c7a-a38a-9b577ca22848',
  managementHIPUri : 'https://ac-api-{env}-hipmanagement-10001236.azurewebsites.net',
  shortName: <EnvironmentShortNames>'d',
  environment: <Environments>'DEV',
  piiLoggingEnabled: false // log personal and organizational data
};
