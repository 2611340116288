<div class="products-container">

  <div class="products-search-bar">
    <mat-form-field>
      <mat-label>Filter by Business Area</mat-label>
      <mat-select #select [(value)]="selectedBusinessArea" (selectionChange)="businessAreaSelectionChanged($event.value)">
        <mat-option [value]="businessAreaCode.name" *ngFor="let businessAreaCode of businessAreaCodes">
          {{businessAreaCode.display}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field [floatLabel]="'always'">
      <mat-label>Filter by Product</mat-label>
      <input matInput placeholder="Search Products" #input [(ngModel)]="filterText">
    </mat-form-field>
  </div>

  <div class="table-container mat-elevation-z8" *ngIf="dataSource.products$ else loading">
    <mat-table class="apis-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="productName"
      matSortDirection="asc" matSortDisableClear multiTemplateDataRows>

      <ng-container matColumnDef="productTile">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Products</mat-header-cell>
        <mat-cell *matCellDef="let product">
          <app-product-tile [product]="product"></app-product-tile>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" style="display: none;" ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="routeToProductDetails(row)"></mat-row>

     
    </mat-table>
    <ng-container *ngIf="loading$ | async">
      <div class="overlay"></div>
      <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </ng-container>
  </div>
  <mat-paginator [length]="productsCount" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
  
</div>

<ng-template #loading>
  <mat-label>Loading Products</mat-label>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>

