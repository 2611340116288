import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HipManagementService } from '../../services/hip-management/hip-management.service';
import { IHealt } from '../../models/health.model';
import { StateService } from 'src/app/services/state/State.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent implements OnInit, OnDestroy {
  @Input()
  healt$: Observable<IHealt>;
  
  constructor(private stateService: StateService, private hipManagementService: HipManagementService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.stateService.setTitle("Monitor");
    this.healt$ = this.hipManagementService.resolveHealth();
  }

  onSubmit= () => {
    let data: IHealt; 
    this.healt$.subscribe(health => data = health);
    console.log(data);
  }
}
