import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomMaterialModule } from './custom-material.module';

import { environment } from '../../environments/environment';

import { NavigationComponent } from '../core/navigation/navigation.component';

import { HealthComponent } from '../ui/health/health.component';
import { RequestSubscriptionConfirmationComponent } from '../ui/obsolete/request-subscription-confirmation/request-subscription-confirmation.component';
import { ApprovalsComponent } from '../ui/approvals/approvals-list/approvals.component';

import { HandleErrorsInterceptor } from '../core/interceptors/handelerrors-interceptor';
import { HipManahementInterceptor } from '../services/hip-management/interceptors/hip-management-interceptor';
import { SubscriptionsComponent } from '../ui/subscriptions/subscriptions-list/subscriptions.component';
import { SubscriptionDetailComponent } from '../ui/subscriptions/subscription-details/subscription-detail.component';
import { SubscriptionCreateComponent } from '../ui/subscriptions/subscription-create.component';
import { RequestSubscriptionComponent } from '../ui/subscriptions/request-subscription/request-subscription.component';
import { ApprovalDetailComponent } from '../ui/approvals/approval-details/approval-detail.component';
import { ProcessorComponent } from '../ui/email/processor/processor.component';
import { NormalizeresponseInterceptor } from '../core/interceptors/normalizeresponse.interceptor';
import { ProductsComponent } from '../ui/products/products.component';
import { CreateProductComponent } from '../ui/products/product-create/product-create.component';
import { ProductApiDetailComponent } from '../ui/api/product-api-details/product-api-detail.component';
import { UpsertApiComponent } from '../ui/api/api-upsert/upsert-api.component';
import { ApisComponent } from '../ui/api/api-list/apis.component';
import { ApiProductsComponent } from '../ui/api/api-product-list/apiproducts.component';
import { ProductTileComponent } from '../ui/products/product-tile/product-tile.component';
import { SwaggerUiComponent } from '../ui/swagger/swagger-ui.component';
import { EnvironmentShortNames } from '../types/Environments';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { NetworkInterceptor } from '../ui/network.interceptor';
import { RejectDialogComponent } from '../ui/approvals/approval-details/dialog/reject-dialog.component';
import { DeleteDialogComponent } from '../ui/subscriptions/subscription-details/dialog/delete-dialog.component';
import { ApiSubscriptionComponent } from '../ui/api/api-subscription-list/api-subscription.component';
import { AppRegistrationComponent } from '../ui/appRegistrations/clientappregistration.component';
import { DownloadDialogComponent } from '../ui/subscriptions/subscription-details/dialog-download-http/dialog-download-http.component';
import { preventSpecialCharacters } from '../core/directives/preventSpecialCharacters';
import { DatePipe } from '@angular/common';
import { MyapisComponent } from '../ui/myapis/myapis.component';
import { MyapisSubscriptionComponent } from '../ui/myapis/myapis-subscription-list/myapis-subscription.component';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources 
 * added to protectedResourceMap. For more info, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.hipManagementApi.endpoint, protectedResources.hipManagementApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}


@NgModule({
  declarations: [
    AppComponent,
    RequestSubscriptionComponent,
    RequestSubscriptionConfirmationComponent,
    SubscriptionsComponent,
    SubscriptionDetailComponent,
    HealthComponent,
    NavigationComponent,
    ApprovalsComponent,
    SubscriptionCreateComponent,
    ApprovalDetailComponent,
    ProcessorComponent,
    ProductsComponent,
    CreateProductComponent,
    ProductApiDetailComponent,
    UpsertApiComponent,
    ApisComponent,
    ApiProductsComponent,
    ProductTileComponent,
    SwaggerUiComponent,
    RejectDialogComponent,
    DeleteDialogComponent,
    ApiSubscriptionComponent,
    AppRegistrationComponent,
    DownloadDialogComponent,
    preventSpecialCharacters,
    MyapisComponent,
    MyapisSubscriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    // FlexLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    ToastrModule.forRoot({
      timeOut: 10000
    })
  ],
  providers: [
    { provide: Window, useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    DatePipe,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HipManahementInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NormalizeresponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor() {
    var rootUrl = window.location.origin;
    if (rootUrl.indexOf('api-catalog.atlascopco.com') > -1) {
      environment.managementHIPUri = environment.managementHIPUri.replace('{env}', 'd');
      environment.shortName = 'd';
    } else {
      if (environment.managementHIPUri.indexOf('{env}') == -1) {
        console.error(`the manegementHIPUri does not contain {env}. This is probably a configuration error. Using ${environment.managementHIPUri}`);
      }
      var urlParts = rootUrl.split('-');
      if (urlParts.length > 2) {
        var shortEnvironment: EnvironmentShortNames = <EnvironmentShortNames>urlParts[2];
        if (shortEnvironment != undefined) {
          environment.managementHIPUri = environment.managementHIPUri.replace('{env}', shortEnvironment);
          environment.shortName = shortEnvironment;

        }
      } else {
        // localhost
        environment.managementHIPUri = environment.managementHIPUri.replace('{env}', 'dh');
        environment.shortName = 'dh';
        if (rootUrl.indexOf('localhost') == -1) {
          console.error(`Unexpected management API url: ${rootUrl}. Url set to ${environment.managementHIPUri}`);
        }
      }
    }
    switch (environment.shortName) {
      case 'd':
        environment.environment = 'DEV';
        break;
      case 't':
        environment.environment = 'TST';
        break;
      case 'p':
        environment.environment = 'PRD';
        break;
      case 'dh':
        environment.environment = 'InternalDEV';
        break;
      case 'th':
        environment.environment = 'InternalTST';
        break;
      default:
        throw new Error(`Environment '${environment.shortName}' ('${environment.environment}') not implemeneted `);
    }
    console.log(`Running environment '${environment.shortName}' ('${environment.environment}')`)
  }
}
