<mat-grid-list cols="2" rowHeight="2:1" gutterSize="5px">
    <mat-grid-tile colspan="2">
        <mat-card>
            <div class="example-container mat-elevation-z8">

                <mat-table *ngIf="products else loading" class="mat-elevation-z8" [dataSource]="products">

                <!-- <table mat-table *ngIf="products else loading" class="mat-elevation-z8" [dataSource]="products"> -->

                    <!-- Product Name Column -->
                    <ng-container matColumnDef="productName">
                        <mat-header-cell *matHeaderCellDef>Product name</mat-header-cell>
                        <mat-cell *matCellDef="let product" (click)="routeToProducts(api)">{{product.productName}}</mat-cell>                 
                    </ng-container>
                   

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let product">
                            <button mat-button (click)="routeToRequestSubscription(product)">Subscribe</button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>   
               
            </mat-table>
            </div>

        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
<ng-template #loading>
    <mat-label>Loading Products</mat-label>
    <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>