import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';
import { StateService } from 'src/app/services/state/State.service';
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from 'swagger-ui-dist';
import { ApiSpec } from '../../models/apiSpec';
import { Environments } from 'src/app/types/Environments';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../loading.service';
import { BaseComponent } from 'src/app/core/base-classes/base-component';


declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-swagger-ui',
  templateUrl: './swagger-ui.component.html',
  styleUrls: ['./swagger-ui.component.css']
})
export class SwaggerUiComponent extends BaseComponent implements OnInit {
  urls: ApiSpec[];
  apimApiName: string;
  urlList: string[];

  htmlToAdd: string;
  environment: string;
  environmentSelection: Environments;
  loading$ = this.loader.loading$;
  defaultBaseUrl: string;

  constructor(
    private hipManagementService: HipManagementService,
    private stateService: StateService,
    private route: ActivatedRoute,
    public loader: LoadingService) {
      super();
     }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.apimApiName = params['apimApiName'];
      this.environmentSelection = this.stateService.Environment;

      this.stateService.environmentSelection$
        .pipe(takeUntil(this.destroyed))
        .subscribe(
          (next: Environments) => {
            this.environmentSelection = next;
            this.process();
            this.defaultBaseUrl = "internal";
          });
    });
  }

  process() {
  this.hipManagementService.GetApiContract(this.environmentSelection, this.apimApiName)
    .subscribe((links: ApiSpec[]) => {
      this.urls = links;

      if (!!this.urls && this.urls?.length > 0) {

      if (this.urls?.length === 1)
      {
        this.htmlToAdd = '<span><a target="_blank" href="' + this.urls[0]["Url"] + '">' +
        this.urls[0]["Name"] + '</a></span> specification';
      }

      if (this.urls?.length === 2)
      {
        var link1 = '<span><a target="_blank" href="' + this.urls[0]["Url"] + '">' +
        this.urls[0]["Name"] + '</a></span>';

        var link2 = '<span><a target="_blank" href="' + this.urls[1]["Url"] + '">' +
        this.urls[1]["Name"] + '</a></span>';

        this.htmlToAdd =  link1 + "<span> | </span>" + link2 + " specification";
      }
      
      var url='';

      if(this.urls.length>1){
        url=this.urls[1]["Url"];
      }
      else{ 
        url=this.urls[0]["Url"];
       }
       if(url!=''){
          const ui = SwaggerUIBundle({
          url: url,
          dom_id: '#swagger-ui',
          layout: 'BaseLayout',
          deepLinking: true,
          presets: [
            SwaggerUIBundle.presets.apis,
            SwaggerUIBundle.SwaggerUIStandalonePreset
          ],
          docExpansion: 'list',
          operationsSorter: 'alpha',
          supportedSubmitMethods: []
         });
       }
      else{
          this.htmlToAdd = "No data available";
      }
    }
    else {
      this.htmlToAdd = "No data available";
    }

    });
  }
}
