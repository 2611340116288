<table mat-table class="mat-elevation-z8 detail-container" [dataSource]="apiUsages$ | async">

  <!-- Api Name Column -->
  <ng-container matColumnDef="apiName">
    <th mat-header-cell *matHeaderCellDef>API name</th>
    <td mat-cell *matCellDef="let element"> {{element.api.name}} </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
  </ng-container>

  <!-- SubscriptionName Column -->
  <ng-container matColumnDef="subscriptionName">
    <th mat-header-cell *matHeaderCellDef>Subscription</th>
    <td mat-cell *matCellDef="let element"> {{element.subscription.subscriptionName}} </td>
  </ng-container>

  <!-- costCenter Column -->
  <ng-container matColumnDef="costCenter">
    <th mat-header-cell *matHeaderCellDef>Cost center</th>
    <td mat-cell *matCellDef="let element"> {{element.subscription.costCenter}} </td>
  </ng-container>

  <!-- famCode Column -->
  <ng-container matColumnDef="famCode">
    <th mat-header-cell *matHeaderCellDef>Fam code</th>
    <td mat-cell *matCellDef="let element"> {{element.subscription.famCode}} </td>
  </ng-container>

  <!-- sponsorName Column -->
  <ng-container matColumnDef="sponsorName">
    <th mat-header-cell *matHeaderCellDef>Sponsor name</th>
    <td mat-cell *matCellDef="let element"> {{element.subscription.sponsorName}} </td>
  </ng-container>

  <!-- businessAreaCode Column -->
  <ng-container matColumnDef="businessAreaCode">
    <th mat-header-cell *matHeaderCellDef>Business area code</th>
    <td mat-cell *matCellDef="let element"> {{element.subscription.businessAreaCode}} </td>
  </ng-container>

  <!-- User Email Column -->
  <ng-container matColumnDef="userEmail">
    <th mat-header-cell *matHeaderCellDef>User-email</th>
    <td mat-cell *matCellDef="let element"> {{element.subscription.user.userEmail}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="routeToApiUsageDetails(row)"></tr>

</table>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</ng-container>

