import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ISubscriptionKey } from 'src/app/models/isubscriptionKey';
import { Subscription } from 'rxjs';
import { IHttpFileSample } from 'src/app/models/IHttpFileSample';


@Component({
    selector: 'app-dialog-download-http',
    templateUrl: './dialog-download-http.component.html',
    styleUrls: ['./dialog-download-http.component.css']
})
export class DownloadDialogComponent implements OnInit {

    form: FormGroup;
    description: string;
    regexStr = /[^A-Za-z0-9]/g;
    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<DownloadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) subscription: ISubscriptionKey) {

        // this.receivedSubscriptionName = subscription;
        this.description = 'This action will download a http test file for : [' + subscription.subscriptionName + '].';
        // this.apiNames = apiNames;

        this.form = fb.group({
            namespace: ['', Validators.required],
            notificationType: ['', Validators.required],
            callbackUrl: ['https://replacewithunprotecteduri.requestcatcher.com', Validators.required],
            protectedCallbackUrl: ['https://replacewithprotecteduri.requestcatcher.com', Validators.required],
            subscriptionName: [subscription.subscriptionName.replace(this.regexStr, ""), Validators.required],
            subscriptionName_with_oauth: [`${subscription.subscriptionName}-oauth`.replace(this.regexStr, ""), Validators.required]
        });
    }

    ngOnInit() {
    }

    confirmDownload() {
        if (this.form.valid) {
            var response: IHttpFileSample = {
                namespace: this.form.value.namespace,
                callbackUrl: this.form.value.callbackUrl,
                notificationType: this.form.value.notificationType,
                protectedCallbackUrl: this.form.value.protectedCallbackUrl,
                subscriptionName: this.form.value.subscriptionName,
                subscriptionName_with_oauth: this.form.value.subscriptionName_with_oauth
            };
            this.dialogRef.close(response);
        }
    }

    close() {
        this.dialogRef.close();
    }

    get namespace(): AbstractControl {
        return this.form.get('namespace');
    }

    get notificationType(): AbstractControl {
        return this.form.get('notificationType');
    }

    get callbackUrl(): AbstractControl {
        return this.form.get('callbackUrl');
    }

    get protectedCallbackUrl(): AbstractControl {
        return this.form.get('protectedCallbackUrl');
    } 
    get subscriptionName(): AbstractControl {
        return this.form.get('subscriptionName');
    } 
    get subscriptionName_with_oauth(): AbstractControl {
        return this.form.get('subscriptionName_with_oauth');
    } 

}
