<h2 mat-dialog-title>Download HTTP test file for VSCode</h2>

<mat-dialog-content [formGroup]="form">

    <!-- namespace -->
    <mat-form-field appearance="fill" value="">
        <label>Namespace</label>
        <input matInput placeholder="e.g. ct.basicequipment" formControlName="namespace"
            required>
        <mat-error *ngIf="(namespace.touched || namespace.dirty) && namespace.invalid">
            Required
        </mat-error>
    </mat-form-field>

    <!-- notificationType -->
    <mat-form-field appearance="fill" value="">
        <label>Notification type</label>
        <input matInput placeholder="e.g. product.updated" formControlName="notificationType" required>
        <mat-error *ngIf="(notificationType.touched || notificationType.dirty) && notificationType.invalid">
            Required
        </mat-error>
    </mat-form-field>

    <!-- callbackUrl -->
    <mat-form-field appearance="fill" value="">
        <label>Callback Url</label>
        <input matInput placeholder="" formControlName="callbackUrl" required>
        <mat-error *ngIf="(callbackUrl.touched || callbackUrl.dirty) && callbackUrl.invalid">
            Required
        </mat-error>
    </mat-form-field>

    <!-- protectedCallbackUrl -->
    <mat-form-field appearance="fill" value="">
        <label>Protected Callback Url</label>
        <input matInput formControlName="protectedCallbackUrl" required>
        <mat-error *ngIf="(protectedCallbackUrl.touched || protectedCallbackUrl.dirty) && protectedCallbackUrl.invalid">
            Required
        </mat-error>
    </mat-form-field>

    <!-- subscriptionName -->
    <mat-form-field appearance="fill" value="">
        <label>Subscription name</label>
        <input matInput formControlName="subscriptionName" required IsAlphaNumeric>
        <mat-error *ngIf="(subscriptionName.touched || subscriptionName.dirty) && subscriptionName.invalid">
            Required
        </mat-error>
    </mat-form-field>

    <!-- subscriptionName oauth-->
    <mat-form-field appearance="fill" value="" IsAlphaNumeric>
        <label>Subscription name for oauth</label>
        <input matInput formControlName="subscriptionName_with_oauth"
            required>
        <mat-error
            *ngIf="(subscriptionName_with_oauth.touched || subscriptionName_with_oauth.dirty) && subscriptionName_with_oauth.invalid">
            Required
        </mat-error>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

    <button class="mat-raised-button" (click)="close()">
        Close
    </button>

    <button class="mat-raised-button mat-primary" (click)="confirmDownload()">
        Confirm Download
    </button>

</mat-dialog-actions>