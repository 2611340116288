import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HealthComponent } from '../ui/health/health.component';
import { ApprovalsComponent } from '../ui/approvals/approvals-list/approvals.component';
import { SubscriptionsComponent } from '../ui/subscriptions/subscriptions-list/subscriptions.component';
import { RequestSubscriptionConfirmationComponent } from '../ui/obsolete/request-subscription-confirmation/request-subscription-confirmation.component';
import { SubscriptionDetailComponent } from '../ui/subscriptions/subscription-details/subscription-detail.component';
import { RequestSubscriptionComponent } from '../ui/subscriptions/request-subscription/request-subscription.component';
import { ApprovalDetailComponent } from '../ui/approvals/approval-details/approval-detail.component';
import { ProcessorComponent } from '../ui/email/processor/processor.component';
import { ProductsComponent } from '../ui/products/products.component';
import { CreateProductComponent } from '../ui/products/product-create/product-create.component';
import { ProductApiDetailComponent } from '../ui/api/product-api-details/product-api-detail.component';
import { UpsertApiComponent } from '../ui/api/api-upsert/upsert-api.component';
import { ApisComponent } from '../ui/api/api-list/apis.component';
import { ApiProductsComponent } from '../ui/api/api-product-list/apiproducts.component';
import { SwaggerUiComponent } from '../ui/swagger/swagger-ui.component';
import { RoleGuardService } from './role-guard.service';
import { roles } from './auth-config';
import { ApiSubscriptionComponent } from '../ui/api/api-subscription-list/api-subscription.component';
import { AppRegistrationComponent } from '../ui/appRegistrations/clientappregistration.component';
import { MyapisComponent } from '../ui/myapis/myapis.component';
import { MyapisSubscriptionComponent } from '../ui/myapis/myapis-subscription-list/myapis-subscription.component';

const routes: Routes = [
  {
    // Needed for hash routing, msal pases state via this route to prevent cross-site request forgery attacks
    path: 'state',
    component: ProductsComponent
  },
  {
    // Needed for hash routing, azure ad return route /code with the token
    path: 'code',
    component: ProductsComponent
  },
  {
    // Needed for hash routing, same if a authentication erroroccurs
    // maybe intresting to do something with it?
    path: 'error',
    component: ProductsComponent
  },
  {
    path: '',
    redirectTo: 'products',
    pathMatch: 'full'
  },
  { path: 'products/:productname/request-subscription/:productenvironmentid', component: RequestSubscriptionComponent, canActivate: [MsalGuard, RoleGuardService] },
  { path: 'request-subscription-confirmation', component: RequestSubscriptionConfirmationComponent },
  { path: 'approvals', component: ApprovalsComponent, canActivate: [MsalGuard, RoleGuardService] },
  {
    path: 'approvaldetail',
    component: ApprovalDetailComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      expectedRole: roles.ApiCatalogApprovers
    }
  },
  { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [MsalGuard, RoleGuardService] },
  { path: 'subscriptions/:productname/subscription/:subscriptionid',
    component: SubscriptionDetailComponent, canActivate: [MsalGuard, RoleGuardService] },
  { path: 'request-subscription-sample', component: SubscriptionDetailComponent, canActivate: [MsalGuard, RoleGuardService] },
  { path: 'monitor', component: HealthComponent },
  { path: 'email/processor', component: ProcessorComponent, canActivate: [MsalGuard, RoleGuardService] },
  { path: 'myapis', component: MyapisComponent, canActivate: [MsalGuard, RoleGuardService] },
  { path: 'products', component: ProductsComponent, canActivate: [MsalGuard, RoleGuardService] },
  { path: 'products/create-product', component: CreateProductComponent, canActivate: [MsalGuard, RoleGuardService]},
  { path: 'product-api-detail', component: ProductApiDetailComponent, canActivate: [MsalGuard,RoleGuardService]},
  { path: 'upsert-api', component: UpsertApiComponent, canActivate: [MsalGuard,RoleGuardService]},
  { path: 'apis', component: ApisComponent, canActivate: [MsalGuard,RoleGuardService]},
  { path: 'apis/:apiid/subscriptions', component: ApiSubscriptionComponent, canActivate: [MsalGuard, RoleGuardService]},
  { path: 'myapis/:apiid/subscriptions', component: MyapisSubscriptionComponent, canActivate: [MsalGuard, RoleGuardService]},
  { path: 'subscription/clientAppRegistration/:clientServicePrincipalId', component: AppRegistrationComponent, 
    canActivate: [MsalGuard, RoleGuardService]},
  { path: 'apiproducts', component: ApiProductsComponent, canActivate: [MsalGuard,RoleGuardService]},
  { path: 'openapi/:apimApiName', component: SwaggerUiComponent, canActivate: [MsalGuard, RoleGuardService]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    useHash: true, 
    // relativeLinkResolution: 'legacy',
    initialNavigation: isInIframe() ? 'disabled' : undefined
   })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
