import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionQuery } from 'src/app/state/session.query';
import { SessionStore } from 'src/app/state/session.store';
import { Environments } from 'src/app/types/Environments';
import { StateService } from '../../../services/state/State.service';
import { ProcessorRoutetypes } from '../../../types/processorRoutes';

@Component({
  selector: 'app-processor',
  templateUrl: './processor.component.html',
  styleUrls: ['./processor.component.css'],
  // providers: [SessionStore, SessionQuery]
})
export class ProcessorComponent implements OnInit {
  routeTo: string;
  subscriptionId: string;
  productName: string;
  environment: Environments;

  message: string;

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.routeTo = queryParams.get("route");
      switch (<ProcessorRoutetypes>this.routeTo) {
        case 'approvals':
          this.environment = <Environments>queryParams.get("environment");
          this.stateService.updateEnvironmentSelection(this.environment);
          this.stateService.routeToApprovalsByEnvionment(this.environment);
          break;
        case 'subscriptionDetail':
          this.subscriptionId = queryParams.get("subscriptionId");
          this.stateService.routeToSubscriptionDetails(this.subscriptionId, this.productName);
          break;
        case 'requestSubscription':
          this.productName = queryParams.get("productName");
          this.environment = <Environments>queryParams.get("environment");
          // this.stateService.routeToRequestSubscription(this.productName, this.environment, );
          break;
        default:
          this.message = `Invalid route: ${this.routeTo}`;
          break;
      }
    });
  }
}
