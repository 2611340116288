import { Component, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ICreateNewSubscription } from './models/icreateNewSubscription';
// import { ApiResult } from '../base.service';

@Component({
  selector: 'app-subscription-create',
  templateUrl: './subscription-create.component.html',
  styleUrls: ['./subscription-create.component.css']
})
export class SubscriptionCreateComponent {

  // the view title
  title: string;

  // the form model
  form: FormGroup;

  // the subscription create
  createNewSubscription: ICreateNewSubscription;

  constructor(
    // private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    }

  ngOnInit() {
    this.form = new FormGroup({
      environment: new FormControl('', Validators.required),
      productName: new FormControl('', Validators.required),
      requester: new FormControl('', Validators.required),
      costCenter: new FormControl('', Validators.required),
      famCode: new FormControl('', Validators.required),
      sponsorName: new FormControl('', Validators.maxLength(50)),
      businessAreaCode: new FormControl('', Validators.required),
      usageNotes: new FormControl('', Validators.maxLength(500))
    });

    this.loadData();
  }


  loadData() {
      this.title = "Create a new Subscription";
  }

  onSubmit() {

    const headers = new HttpHeaders()
    .set('ApiKey', 'ea130697-65d0-4c7a-a38a-9b577ca22848');

    var createNewSubscription = <ICreateNewSubscription>{};

    createNewSubscription.environment = this.form.get("environment").value;
    createNewSubscription.productName = this.form.get("productName").value;
    createNewSubscription.email = this.form.get("email").value;
    createNewSubscription.costCenter = this.form.get("costCenter").value;
    createNewSubscription.famCode = this.form.get("famCode").value;
    createNewSubscription.sponsorName = this.form.get("sponsorName").value;
    createNewSubscription.businessAreaCode = this.form.get("businessAreaCode").value;
    createNewSubscription.usageNotes = this.form.get("usageNotes").value;
    

    // ADD NEW
    var url = this.baseUrl + "api/Subscription";

    // this.http
    //     .post<ApiResult<SubscriptionCreatedResult>>(url, createNewSubscription, {headers})
    //     .subscribe(result => {
    //      var d = JSON.stringify(result.result);
    //      this.router.navigate(['/detailSubscription', JSON.stringify(result.result)]);
    //     }, error => console.log(error));
  }
}
