<mat-grid-list cols="2" rowHeight="fit"   gutterSize="5px">


    <mat-grid-tile>
        <mat-card class="card-subscription">
            <mat-card-title-group>
                <mat-card-title>Attention !</mat-card-title>
                <mat-card-subtitle> 
                    When you don't provide your own Client App Registration for this Subscription, we will use the default one
                    that is linked to your User in the API Catalog.
                    We do not allow to use the same Client App Registration when this has already been used for the same
                    Publisher App Registration (the one attached to the API that is part of the Product of this Subscription).
                    <p>Please fill out the below information which might be used by the individual API Provider(s) for
                        billing
                        purposes.</p>
                </mat-card-subtitle>
            </mat-card-title-group>
          
            <mat-card-content>
                <form fxLayout="column" [formGroup]="subscribeForm">

                    <!-- environment  -->
                    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Environment" formControlName="environment">
                        </mat-form-field>
                    </div>

                    <!-- productName -->
                    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Product Name" formControlName="productName">
                        </mat-form-field>
                    </div>

                    <!-- requester -->
                    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Requester" formControlName="email">
                        </mat-form-field>
                    </div>
                    <!-- CMDB ID -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text"  (focusout)="loadDetailsById($event)" placeholder="Application Id" formControlName="cmdb"
                                matTooltip="Provide the unique Id from the CMDB for the subscribing application" required>
                        </mat-form-field>
                    </div>

                    <!-- ApplicationName -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Application Name" formControlName="applicationName"
                                matTooltip="Enter the application name">
                        </mat-form-field>
                    </div>

                    <!-- businessAreaCode -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <!-- <input matInput type="text" placeholder="Business Area Code" formControlName="businessAreaCode" matTooltip="Enter your internal business area code"> -->
                            <mat-select placeholder="Business Area Code" matNativeControl required
                                formControlName="businessAreaCode">
                                <mat-option value="IT">IT</mat-option>
                                <mat-option value="CT">CT</mat-option>
                                <mat-option value="VT">VT</mat-option>
                                <mat-option value="PT">PT</mat-option>
                                <mat-option value="CO">CO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <!-- costCenter -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Cost Center" formControlName="costCenter"
                                matTooltip="Enter the cost center were to invoice consumptions" required>
                        </mat-form-field>
                    </div>

                    <!-- famCode -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Unit Code" formControlName="famCode"
                                matTooltip="Enter your internal division code" required>
                        </mat-form-field>
                    </div>

                  

                    <!-- sponsorName -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Sponsor Name" formControlName="sponsorName"
                                matTooltip="Enter the sponsor name">
                        </mat-form-field>
                    </div>

                   
                        <!-- optional Client App Registration -->
                        <div class="input-row" *ngIf="!isClientAppMandatory">
                            <mat-form-field fxFlex>
                                <input matInput type="text" placeholder="Client App Registration AppId (optional, use only in case of OAuth)"
                                    formControlName="clientAppRegistrationAppId" matTooltip="Provide the AppId of the 
                                    Client App Registration in Azure Active Directory for the subscribing application">
                            </mat-form-field>
                        </div>

                        <div class="input-row" *ngIf="isClientAppMandatory">
                            <mat-form-field fxFlex>
                                <input matInput type="text" placeholder="Client App Registration AppId"
                                    formControlName="clientAppRegistrationAppId" required matTooltip="Provide the AppId of the 
                                    Client App Registration in Azure Active Directory for the subscribing application">
                            </mat-form-field>
                        </div>
                

                    <!-- usageNotes -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <mat-label>Usage Notes</mat-label>
                            <textarea matInput placeholder="Usage Notes" formControlName="usageNotes"
                                matTooltip="Provide a reason why this subscription is still required over HIP 1.1 platform" matInput
                                placeholder="Additional comments related to this subscription request" required title=""></textarea>
                            <!-- <input matInput type="text" placeholder="Usage notes" formControlName="usageNotes" matTooltip="Enter any usage notes"> -->
                        </mat-form-field>
                    </div>
                </form>
            
            </mat-card-content>
            <mat-card-actions>
                <button mat-flat-button disabled
                >Request subscription</button>
            </mat-card-actions>

            <mat-error *ngIf="validationError">
                <p>{{validationError}}</p>
            </mat-error>
        </mat-card>
    </mat-grid-tile>

   

    <mat-grid-tile>
        <mat-card class="card-subscription">
            <mat-card-header>
                <mat-card-title>You are about to submit a request to get access to the API Product
                    [{{productName}}].

                </mat-card-title>
                <mat-card-subtitle>
                    <p>Depending on the configuration of the individual API(s), some API Providers might have to perform
                        a
                        manual step
                        in your request process, others will be processed automatically.
                    </p>
                    <p>This product includes the following APIs :</p>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="table-container mat-elevation-z8">

                <table mat-table *ngIf="apis else loading" class="apis-table mat-elevation-z8" [dataSource]="apis">

                    <!-- Api Name Column -->
                    <ng-container matColumnDef="apiName">
                        <th mat-header-cell *matHeaderCellDef>API Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- Api Owner Column -->
                    <ng-container matColumnDef="apiOwner">
                        <th mat-header-cell *matHeaderCellDef>API Owner</th>
                        <td mat-cell *matCellDef="let element"> {{element.owner}} </td>
                    </ng-container>
                    
                    <!-- OAuth Column -->
                    <ng-container matColumnDef="oauth">
                        <th mat-header-cell *matHeaderCellDef>Security</th>
                        <td mat-cell *matCellDef="let element"> {{getOAuthIndication(element)}} </td>
                    </ng-container>

                    <tr class="mat-caption" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr class="mat-display-1" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

            </mat-card-content>
        </mat-card>
    </mat-grid-tile>

    <div class="wrapper">
        <mat-progress-spinner mode="indeterminate" *ngIf="isWait" ></mat-progress-spinner>
    </div>
</mat-grid-list>


<ng-template #loading>
    <mat-label>Loading details</mat-label>
    <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>