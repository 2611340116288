export class MSALConstanst {
    static MSAL_loginSuccess_BroadcastString = "msal:loginSuccess";
    static MSAL_loginFailure_BroadcastString = "msal:loginFailure";
}

export class ApiConstants {
    static API_GetApisByProductEnvironmentId = '/api/Product/GetApisByProductEnvironment';
    static API_GetApisByProductName = '/api/Api/GetApisByProduct';
    static API_GetApiSpec = '/api/Api/GetApiSpec';
    static API_GetApisForSubscription = '/api/Subscription/GetApisForSubscription';
    static API_GetHttpFileForSubscription = '/api/Subscription/DownloadHttpFile';
    static API_GetProductsByApi = '/api/Product/GetProductsByApi';
    static API_GetProducts = '/api/product/GetProductsWithApis';
    static API_GetTotalProducts = '/api/product/GetTotalProducts';
    static API_DeleteSubscription = '/api/Subscription';
    static API_RejectSubscription = '/api/Subscription/Reject';
    static API_GetSubscriptionKey = '/api/Subscription/GetSubscriptionKey';
    static API_GetSubscriptionsByEmail = '/api/Subscription';
    static API_GetSubscriptionsForApi = '/api/Api/GetSubscriptions';
    static API_GetSubscriptionsForMyApi = '/api/Api/GetMyApisSubscriptions';
    static API_GetSubscriptionsListForApi = '/api/Api/GetListOfSubscriptions';
    static API_GetSubscriptionsListForMyApi = '/api/Api/GetMyApisSubscriptionList';
    static API_GetApiUsageForApproversByEmail = '/api/ApiUsage/GetApiUsageToApproveForApproversByEmail';
    static API_GetApiUsageBySubscriptionId = '/api/ApiUsage/GetApiUsageBySubscriptionId';
    static API_ApiUsage = '/api/ApiUsage';
    static API_GetAssignedAppRoles = '/api/AppRegistration/GetAssignedAppRoles';

    static API_Subscribe = '/api/Subscription';
    static API_Tracing = '/api/Subscription/EnableTracingBySubcription';
    static API_CreateProduct = '/api/product';
    static API_CreateApi = '/api/Api';
    static API_DeleteProduct = '/api/Product';

    static API_DeleteApi = '/api/Api';
    static API_GetTotalApis = '/api/Api/GetTotalApis';
    static API_GetApis = '/api/Api/GetApis';
    static API_GetMyApis = '/api/Api/GetMyApis';

    static API_GetDetailsByCmdbId="/api/ServiceNow/Index"

    static API_Health = '/sys/health';
}
