<mat-grid-list cols="1" rowHeight="2:1" gutterSize = "5px">
<mat-grid-tile>
    <mat-card>
        <mat-card-header>
            <mat-card-title>You are about to create a new Product.
            </mat-card-title>          
        </mat-card-header>
        <mat-card-content>
            <form fxLayout="column" [formGroup]="productForm">          

                <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="environment" formControlName="environment">
                </mat-form-field>
               
                <div class="input-row">
                    <mat-form-field fxFlex>
                        <mat-select placeholder="Business Area Code" matNativeControl required
                            formControlName="businessAreaCode" matTooltip="Select your internal business area code">
                            <mat-option *ngFor="let item of businessAreaCodes" value="{{item.name}}">{{item.display}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- productName -->
                <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px">
                    <mat-form-field fxFlex>
                        <input matInput type="text" placeholder="productName" formControlName="productName" required>
                    </mat-form-field>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button color="primary"  [disabled]="buttonDisAllowed"
            (click)="requestNewProduct()">Create Product</button>
        </mat-card-actions>
    </mat-card>
</mat-grid-tile>
</mat-grid-list>