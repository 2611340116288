<mat-card>
	<mat-card-header>
		<mat-card-title>
			<span class="businessarea-button">{{product.businessArea}}</span>
			&nbsp;&nbsp;&nbsp;
			<span>{{product.name}}</span>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		{{product.description}}
		<div class="filler"></div>
	</mat-card-content>
	<div fxLayout="row" style="justify-content: space-between; display: flex">
		<mat-card-actions class="button-overflow">
			<button fxFlex="none"  class="api-button" *ngFor="let api of product.apis" mat-button (click)="routeToSwagger(api)">{{api.name}}</button>
		</mat-card-actions>
		<div class="spacer"></div>
		<mat-card-actions>
			<button mat-raised-button disabled [disabled]=true>Subscribe</button>
		</mat-card-actions>
	</div>
	<!-- <mat-card-footer  align="end">
		<button class="subscribe-button" mat-button  (click)="routeToRequestSubscription(product)">Subscribe</button>
	</mat-card-footer> -->
</mat-card>