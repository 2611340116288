import { Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, AbstractControl} from '@angular/forms';
import { DeleteSubscription } from './deleteSubscription';


@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

    form: FormGroup;
    description: string;
    apiNames: string[];
    reasonDelete: string;
    receivedSubscriptionName: string;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject ( MAT_DIALOG_DATA ) { subscription, apiNames }: DeleteSubscription ) {

        this.receivedSubscriptionName = subscription;
        this.description = 'This action will Delete the following Subscription : [' + subscription + ']. \nThis action cannot be undone! \nPlease type in the Subscription Name to confirm.';
        this.apiNames = apiNames;

        this.form = fb.group({
            confirmDelete: [ '', Validators.required]
        });
    }

    ngOnInit() {

    }


    confirmDelete() {
        if (this.form.valid && this.receivedSubscriptionName === this.form.value.confirmDelete){
            this.dialogRef.close(this.form.value);
        }
    }

    close() {
        this.dialogRef.close();
    }

    get reason(): AbstractControl {
        return this.form.get('confirmDelete');
      }
}
