import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { IApiUsage } from '../../../models/IApiUsage';
import { StateService } from 'src/app/services/state/State.service';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';
import { Environments } from 'src/app/types/Environments';
import { LoadingService } from '../../loading.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent  extends BaseComponent implements OnInit {

  loading$ = this.loader.loading$;
  environmentSelection: Environments;
  apiUsages$: Observable<IApiUsage[]>;
  displayedColumns: string[] = ['apiName', 'status', 'subscriptionName', 'costCenter', 'famCode', 'sponsorName', 'businessAreaCode', 'userEmail'];


  constructor(
    private stateService: StateService,
    private hipManagementService: HipManagementService,
    public loader: LoadingService) {
    super();
  }


  ngOnInit() {
    this.stateService.setTitle("Approvals");

    this.stateService.environmentSelection$
    .pipe(takeUntil(this.destroyed))
    .subscribe(
      (next: Environments) => {
        this.environmentSelection = next;
        this.apiUsages$ = this.hipManagementService.GetApiUsageForApprovers(this.environmentSelection);
      });
  }

  routeToApiUsageDetails(apiUsage: IApiUsage) {
    this.stateService.routeToApprovalDetails(apiUsage);
  }
}
