import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class NormalizeresponseInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
              if(event.body?.Value) {
                var normalizeExvent = event.clone({body:event.body.Value} ); 
                return normalizeExvent;
              }
              if(event.body?.IsFailure == true) {
                throw new HttpErrorResponse({
                  error: event.body.Error,
                  headers: event.headers,
                  status: 500,
                  statusText: 'Error',
                  url: event.url
              });
              }
          }
          return event;
      }));
  }
}
