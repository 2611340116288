import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/internal/operators';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { Product } from '../../models/product';
import { HipManagementService } from '../../services/hip-management/hip-management.service';
import { StateService } from '../../services/state/State.service';
import { BusinessAreaCodes, BusinessAreas } from '../../types/BusinessAreas';
import { Environments } from '../../types/Environments';
import { LoadingService } from '../loading.service';
import { ProductsDataSource } from './product-datasource';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductsComponent extends BaseComponent implements OnInit {
  productsCount: number;
  dataSource: ProductsDataSource;
  businessAreaCodes = BusinessAreaCodes;
  loading$ = this.loader.loading$;

  selectedEnvironment: Environments;
  selectedBusinessAreaCode$ = new BehaviorSubject<BusinessAreas>(<BusinessAreas>'');
  // todo: put in local storage and remember on refresh
  selectedBusinessArea = BusinessAreaCodes[0].name;
  displayedColumns = ['productTile'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') filterTextElement: ElementRef;
  filterText: string;

  constructor(
    private hipManagementService: HipManagementService,
    private stateService: StateService,
    public loader: LoadingService) {
      super();
    }


  ngOnInit() {
    this.stateService.setTitle("Products");
    this.dataSource = new ProductsDataSource(this.hipManagementService);

    this.selectedBusinessAreaCode$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (businessArea: BusinessAreas) => {
          this.selectedBusinessArea = businessArea;
        });

    this.stateService.environmentSelection$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (environment: Environments) => {
          this.selectedEnvironment = environment;
          this.filterText = "";
          this.selectedBusinessArea = "";
          // this.selectedBusinessAreaCode$.next(<BusinessAreas>'');
        }
      );

    this.dataSource.products$
      .pipe(takeUntil(this.destroyed))
      .subscribe((p) => {
        this.productsCount = this.dataSource.totalRecords;
      });

    combineLatest([this.stateService.environmentSelection$, this.selectedBusinessAreaCode$])
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.dataSource.loadProducts(this.selectedEnvironment, this.selectedBusinessArea, '', 'asc', 0, 10);
        this.productsCount = this.dataSource.totalRecords;
      },
        // Error => this.loader.hide(),
        // () => this.loader.hide()
      );
  }


  ngAfterViewInit() {
    this.sort?.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.filterTextElement.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;

          this.loadProductsPage();
        })
      )
      .subscribe();

    merge(this.sort?.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadProductsPage())
      )
      .subscribe();
  }

  loadProductsPage() {
    this.dataSource.loadProducts(
      this.selectedEnvironment,
      this.selectedBusinessArea,
      this.filterText,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  routeToProductDetails(product: Product) {
    //this.stateService.routeToProductDetails(product, this.selectedEnvironment);
  }

  businessAreaSelectionChanged(businessArea: BusinessAreas) {
    this.filterText = "";
    this.selectedBusinessAreaCode$.next(businessArea);
  }
}




