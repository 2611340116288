export type BusinessAreas = "" | "IT" | "SS" | "CT" | "PT" | "VT"

export const BusinessAreaCodes = [{
    name: '',
    display: 'All'
  }, {
    name: 'IT',
    display: 'IT'
  }, {
    name: 'CO',
    display: 'CO'
  }, {
    name: 'CT',
    display: 'CT'
  }, {
    name: 'PT',
    display: 'PT'
  }, {
    name: 'VT',
    display: 'VT'
  }];