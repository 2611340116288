import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError, finalize } from 'rxjs/internal/operators';
import { Product, ProductsAndFileterCount } from '../../models/product';
import { HipManagementService } from '../../services/hip-management/hip-management.service';
import { IDeleteProduct } from './models/ideleteProduct';

export class ProductsDataSource implements DataSource<Product> {

    private ProductsSubject = new BehaviorSubject<Product[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public totalRecords: number;

    // public loading$ = this.loadingSubject.asObservable();
    public products$ = this.ProductsSubject.asObservable();

    constructor(private hipManagementService: HipManagementService) {

    }

    connect(collectionViewer: CollectionViewer): Observable<Product[]> {
        return this.ProductsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.ProductsSubject.complete();
        this.loadingSubject.complete();
    }

    loadProducts(env = 'DEV', businessAreaCode = '', filter = '', sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
        this.loadingSubject.next(true);

        this.hipManagementService.findProducts(env, businessAreaCode, filter, sortDirection,
            pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((productsAndCount: ProductsAndFileterCount) => {
                  this.totalRecords = productsAndCount.item2;
                  this.ProductsSubject.next(productsAndCount.item1);
            });
    }

    // async getTotalProducts(env = 'DEV')
    // {
    //   return await this.hipManagementService.getTotalProducts(env);
    // }

    deleteProduct(product: IDeleteProduct)
    {
        this.hipManagementService.deleteProduct(product).subscribe();
    }
}
