import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { IApiUsage } from '../../../models/IApiUsage';
import { StateService } from 'src/app/services/state/State.service';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';
import { ISubscriptionKey } from 'src/app/models/isubscriptionKey';
import { ActivatedRoute, Router } from '@angular/router';
import { Environments } from 'src/app/types/Environments';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../loading.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteDialogComponent } from './dialog/delete-dialog.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { DownloadDialogComponent } from './dialog-download-http/dialog-download-http.component';
import { IHttpFileSample } from 'src/app/models/IHttpFileSample';
import { ToastrService } from 'ngx-toastr';
import { ISubscriptionTracing } from '../models/isubscriptionTracing';

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.css']
})
export class SubscriptionDetailComponent extends BaseComponent implements OnInit {
  // the message for the User
  cardTitle: string;
  cardSubTitle: string;

  // the form model
  subscriptionSecretForm: FormGroup;
  subscriptionUsageNotesForm: FormGroup;

  subscriptionId: string;
  apiUsages: IApiUsage[];
  subscriptionKey: ISubscriptionKey;
  requiresApproval: boolean;
  showTracing:boolean;
  usageNotes: string;
  productName: string;  
  errorMessage: string;
  baseUrl:string;
  environmentSelection: Environments;
  loading$ = this.loader.loading$;
  isActiveDelete: boolean = false;
  noOauth: boolean = false;
  viaMyapis: boolean = false;
  validationError: string = null;
  noOauthMessage: string = '<h4>This Subscription has no Client App Registration because the underlying APIs don\'t have a Proxy.</h4>';
  isNotificationApi: boolean = false;
  displayedColumns: string[] = ['apiName', 'status', 'clientProxyId','owner','security', 'remarks'];  
  displayedColumnsWithHttp: string[] = ['apiName', 'status', 'clientProxyId','owner','security', 'remarks','httpfile'];  
  displayedColumnsWithoutHttp: string[] = ['apiName', 'status', 'clientProxyId','owner','security','remarks'];  

  constructor(
    private dialog: MatDialog,
    private hipManagementService: HipManagementService,
    private route: ActivatedRoute,
    private stateService: StateService,
    private fb: FormBuilder,
    private fb2: FormBuilder,
    public loader: LoadingService,
    private http: HttpClient,
    private router: Router,
    private toaster: ToastrService,) {

    super();

    this.subscriptionSecretForm = this.fb.group({
      subscriptionName: [{ value: '', disabled: true }],
      subscriptionSecret: [{ value: '', disabled: true }],
      clientAppRegistrationId: [{ value: '', disabled: true }],
      clientAppRegistrationDisplayName: [{ value: '', disabled: true }],
      clientAppRegistrationSecret: [{ value: '', disabled: true }],
      usageNotes: [{ value: '', disabled: true }]
    });

    this.subscriptionUsageNotesForm = this.fb2.group({
      usageNotes: [{ value: '', disabled: true }]
    });


    this.route.params.subscribe((params) => {
      this.subscriptionId = params['subscriptionid'];
      this.productName = params['productname'];      
    });
    
    const previousNavigation = this.router.getCurrentNavigation().previousNavigation;
    const previousUrl = previousNavigation.finalUrl.toString();
    if (previousUrl.indexOf('myapis') != -1) {
      this.viaMyapis = true;
    }
    else {
      this.viaMyapis = false;
    }
  }

  ngOnInit() {    
    this.stateService.environmentSelection$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (next: Environments) => {
          if (this.environmentSelection === undefined) {
            this.environmentSelection = next;
          }
          else {
            this.stateService.routeToSubscriptions();
          }
        });

    // this.route.params.subscribe((params) => {
    //   this.productName = params['productname'];
    // });

    if (this.subscriptionId === undefined) {
      this.stateService.routeToSubscriptions();
    } else {
      this.hipManagementService.GetApiUsageForSubscribers(this.subscriptionId).subscribe(r => {
        this.apiUsages = r;

        this.subscriptionUsageNotesForm.setValue({ usageNotes: this.apiUsages[0].subscription.usageNotes });

        if (this.apiUsages.every(x => x.clientProxyId === null)) {
          this.noOauth = true;
        }


        if (this.apiUsages.some(x => x.status === 'Pending Approval') ||
          this.apiUsages.some(x => x.subscription.status === 'SUBMITTED')) {
          this.isActiveDelete = false;
          this.requiresApproval = true;
          this.cardTitle = 'Your subscription needs approval';
          this.cardSubTitle = 'You will receive an email once the Subscription has been approved';
        }
        else if (this.apiUsages.some(x => x.subscription.status === 'REJECTED')) {
          this.isActiveDelete = false;
          this.requiresApproval = true;
          this.cardTitle = 'Your subscription has been Rejected';
          this.cardSubTitle = 'You can remove this Subscription request from the list';
          this.errorMessage = 'Reason Reject : ' + this.apiUsages[0].statusChangedReason;
        }
        else {
          this.hipManagementService.GetSubscriptionKey(this.subscriptionId).subscribe(r2 => {
            this.requiresApproval = false;
            this.isActiveDelete = true;
            this.subscriptionKey = r2;
            this.subscriptionSecretForm.setValue(this.subscriptionKey);
            this.subscriptionUsageNotesForm.setValue({ usageNotes: r2.usageNotes });
          });
          this.cardTitle = 'Your subscription has been created';
          this.cardSubTitle = 'Please find here the details.';
        }

        if (this.apiUsages.some(x => x.api.name.toLowerCase() === 'notification api')) {
          this.isNotificationApi = true;               
        }
        else {
          this.isNotificationApi = false;          
        }
        if (this.requiresApproval) {
          this.displayedColumns = this.displayedColumnsWithoutHttp;
        }
        else {
          this.displayedColumns = this.displayedColumnsWithHttp;
        }             

      });

      this.stateService.setTitle(`Subscription details ${this.productName}`);
    }

    if (this.environmentSelection.trim().toLowerCase() === 'prd') {
      this.showTracing = false;
    }
    else {
      this.showTracing = true;
    }
  }

  enableTracing(){
    let subscriptionData: ISubscriptionTracing = {
      Environment: this.environmentSelection,
      SubscriptionName: this.subscriptionSecretForm.controls['subscriptionName'].value
    };
    this.hipManagementService.enableTracingBySubscription(subscriptionData).subscribe(resp=>{
      this.toaster.success(`Enable tracing succeeded`);
    },
    error => {               
    });  
  }

  Delete() {

    this.hipManagementService.GetApiNames(this.subscriptionId).subscribe(r => {
      const apis = r;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        subscription: this.apiUsages[0].subscription.subscriptionName,
        apiNames: apis.map(function (a) { return a.name; })
      };

      const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(
        data => {

          if (!!data) {
            this.hipManagementService.deleteSubscription(this.subscriptionId, this.isActiveDelete).subscribe(r => {
              this.stateService.routeToSubscriptions();
            });
          }
        }
      );
    });
  }

  downloadHttpFile(paramApimApiName: string, paramClientProxyId: string, paramApiName: string) {    
    var proxyId = paramClientProxyId == null ? "NoOAuth" : paramClientProxyId
    if (paramApiName.toLowerCase() === 'notification api') {
      this.downloadHttpFileDialog();
    }
    else {
      this.hipManagementService.GetHttpFile(this.environmentSelection, paramApimApiName,
        this.subscriptionId, proxyId).subscribe(resp => {                  
        if (resp.fileString != null) { 
          //var data:any = resp.fileString;
          //var replacedData = data.replaceAll("#apiKey#", this.subscriptionKey.subscriptionSecret.trim());      
          this.downloadFile(resp.fileString, resp.fileName);        
        }
        else {
          if (resp.errorMessage.length != 0) {
            resp.errorMessage.forEach(element => {
              this.validationError += element + " "
            });    
            this.toaster.warning(this.validationError);
          }        
        }
      },
      err => {      
        console.error(err)
      });  
    }  
  }

  //#region download http sample file
  downloadHttpFileDialog() {    
    if (this.environmentSelection.trim().toLowerCase() === 'prd') {
      this.baseUrl = "https://api.atlascopco.group";
    }
    else {
      this.baseUrl = "https://api-"+ this.environmentSelection.trim().toLowerCase() +".atlascopco.group";
    }
    this.hipManagementService.GetApiNames(this.subscriptionId).subscribe(apis => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "700px";

      dialogConfig.data = this.subscriptionKey;

      const dialogRef = this.dialog.open(DownloadDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(
        (response: IHttpFileSample) => {
          if (!!response) {
            this.getRawFile().subscribe(data => {
              // replace placeholders
              data = data.replaceAll("#apiKey#", this.subscriptionKey.subscriptionSecret.trim());
              data = data.replaceAll("#namespace#", response.namespace.trim());
              data = data.replaceAll("#applicationScope#", this.subscriptionKey.clientAppRegistrationId.trim());
              data = data.replaceAll("#callbackUrl#", response.callbackUrl.trim());
              data = data.replaceAll("#protectedCallbackUrl#", response.protectedCallbackUrl.trim());
              data = data.replaceAll("#notificationType#", response.notificationType.trim());
              data = data.replaceAll("#subscriptionName#", response.subscriptionName.trim());
              data = data.replaceAll("#environment#", this.environmentSelection.trim().toLowerCase());
              data = data.replaceAll("#baseUrl#", this.baseUrl);
              data = data.replaceAll("#subscriptionName-oauth#", response.subscriptionName_with_oauth.trim());
              this.downloadFile(data);
              this.stateService.routeToSubscriptions();
            });
          }
        }
      );
    });
  }

  downloadFile(data: BlobPart, filename: any = "") {
    const blob = new Blob([data], { type: 'application/text', });
    var filenameValue = filename == "" ? 'notificationapi-' : filename.replaceAll(' ','')+'-';
    var exportFilename: string =  filenameValue + this.environmentSelection.trim().toLowerCase() + '.http';
    //IE11 & Edge
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportFilename);
    } else {
      //In FF link must be added to DOM to be clicked
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public getRawFile(): Observable<any> {
    return this.http.get("assets/notificationapi.http", { responseType: 'text' });
  }
  //#endregion
}
