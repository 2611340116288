import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BackendErrorResponse } from './backend-error-response';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {

  constructor(private toaster: ToastrService) { }

  public HandleError(err: HttpErrorResponse) {
    let errorMessage: string;
    let customErrorMessage: string;
    if (err.error instanceof BackendErrorResponse) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      customErrorMessage = err.error?.message || err.error?.title || err?.error;
      switch (err.status) {
        case 0:
          errorMessage = `Backend not responding: url '${err.url}'`;
          break;
        case 400:
          errorMessage = `${err.status} : Bad Request => '${customErrorMessage}'`;
          break;
        case 401:
          errorMessage = `${err.status} : You are unauthorized to do this action.`;
          break;
        case 403:
          errorMessage = `${err.status} : You don't have permission to access the requested resource.`;
          break;
        case 404:
          errorMessage = `${err.status} : Requested resource does not exist`;
          break;
        case 412:
          errorMessage = `${err.status} : Precondition failed`;
          break;
        case 500:
          errorMessage = `${err.status} : Internal server error. We logged the error and will be noitfied of the issue`;
          break;
        case 503:
          errorMessage = `${err.status} : The requesten backend service is not avialable`;
          break;
        default:
          errorMessage= `${err.status} : ${err.message} :`;
          break;
      }
      // if(err.error.details) {
      //   errorMessage = customErrorMessage || `(Status code: ${err.status}, Message: ${err.error.details}')`;
      // } else {
      //   errorMessage = customErrorMessage || `Something unexpected went wrong (Status code: ${err.status}, Status text: '${err.statusText}, message: ${err.message}')`;
      // }
    }
    this.toaster.error(errorMessage);
  }
}
