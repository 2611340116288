import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/services/state/State.service';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';

import { IApi } from '../../products/models/iapi';


@Component({
  selector: 'app-product-api-detail',
  templateUrl: './product-api-detail.component.html',
  styleUrls: ['./product-api-detail.component.scss']
})
export class ProductApiDetailComponent implements OnInit {

  apis: IApi[];
  displayedColumns: string[] = ['apiName', 'businessAreaCode', 'costCenter', 
  'famCode', 'sponsorName', 'urlSuffix', 'requiresManualApproval'];

  constructor(
    private hipManagementService: HipManagementService,
    private stateService: StateService) {

  }

  ngOnInit(): void {

    // this.hipManagementService.GetApisForProducts(this.stateService.Environment, this.stateService.productName).subscribe(r => {
    //   this.apis = r;
    // });
  }

  updateApi(api: IApi) {

  }
}
