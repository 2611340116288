import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { HandleErrorService } from '../services/handle-error.service';

@Injectable()
export class HandleErrorsInterceptor implements HttpInterceptor {

    constructor(private handleErrorService: HandleErrorService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                event => this.handleResponse(req, event),
                error => this.handleError(req, error)
            )
        );
    }
    
    handleResponse(req: HttpRequest<any>, event) {
        console.log('Handling response for ', req.url, event);
        if (event instanceof HttpResponse) {
            console.log('Request for ', req.url,
                ' Response Status ', event.status,
                ' With body ', event.body);
        }
    }

    handleError(req: HttpRequest<any>, error: HttpErrorResponse) {
        console.error('Request for ', req.url,
            ' Response Status ', error.status,
            ' With error ', error.error);
            this.handleErrorService.HandleError(error)
    }
}