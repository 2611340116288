import { Component } from '@angular/core';
import { BaseComponent } from '../../../core/base-classes/base-component';
import { HipManagementService } from '../../../services/hip-management/hip-management.service';
import { StateService } from '../../../services/state/State.service';
import { Product } from '../../../models/product';
import { Environments } from '../../../types/Environments';

@Component({
    selector: 'app-apiproducts',
    templateUrl: './apiproducts.component.html',
    styleUrls: ['./apiproducts.component.scss']
  })
  export class ApiProductsComponent extends BaseComponent {
    environmentSelection: Environments;
    products: Product[];
    displayedColumns: string[] = ['productName', 'actions'];

    constructor(
        private stateService: StateService,
        private hipManagementService: HipManagementService) {
        super();
      }

    ngOnInit(): void {

        this.hipManagementService.GetProductsForApi(this.stateService.apiId).subscribe(r => {
            this.products = r;
        });

        this.stateService.environmentSelection$.subscribe(
          (next: Environments) => {
            this.environmentSelection = next;

            // this.stateService.routeToApis();
          }
        );
    }

    routeToRequestSubscription(product: Product): void {
     // this.stateService.routeToRequestSubscription(product.productEnvironmentId, product.name, );
    }
}
