import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';
import { StateService } from 'src/app/services/state/State.service';
import { Environments } from 'src/app/types/Environments';
import { LoadingService } from '../../loading.service';
import { ISubscription } from '../../subscriptions/models/isubscription';
import { ISubscriptiondata } from '../../subscriptions/models/isubscriptionData';
import * as XLSX from 'xlsx';
import { isPlatformServer, isPlatformBrowser, DatePipe } from '@angular/common';

@Component({
  selector: 'app-myapis-subscription',
  templateUrl: './myapis-subscription.component.html',
  styleUrls: ['./myapis-subscription.component.scss']
})
export class MyapisSubscriptionComponent extends BaseComponent implements OnInit,OnDestroy {
  ngOnDestroy() {
     
  }
  loading$ = this.loader.loading$;
    environmentSelection: Environments;
    subscriptions$: Observable<ISubscription[]>;
    displayedColumns: string[] = ['productName', 'usageNotes', 'costCenter', 'famCode', 'sponsorName', 'businessAreaCode', 'status', 'activatedDate', 'userEmail', 'clientAppRegistration', 'subscriptionName'];

  constructor(
    private stateService: StateService,
        private hipManagementService: HipManagementService,
        public dated:DatePipe,
        public loader: LoadingService,@Inject(PLATFORM_ID) private platformId: Object) {
        super();
      }

  ngOnInit(): void {
    this.stateService.setTitle('Subscriptions related to API : ' + this.stateService.myApi.name);
    this.stateService.environmentSelection$
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (next: Environments) => {
          this.environmentSelection = next;          
          this.subscriptions$ = this.hipManagementService.GetSubscriptionsForMyApi(this.environmentSelection, this.stateService.myApi.id);
        });    
  }

  ListofSubscription()
{
    this.hipManagementService.GetSubscriptionsListForMyApi(this.environmentSelection, this.stateService.myApi.id).subscribe(r =>
    {
      var result=r;
      this.exportexcel(result);
    }
   );
}
exportexcel(sub:ISubscriptiondata[]): void
  {
   let todaydate = this.dated.transform(new Date(),'yyyyMMdd'+'_'+'HHmmss');  
 
     
    let fileName= "SubscriptionsList_"+todaydate+".xlsx";
    /* pass here the table id */
    let element1 = sub;
    //localStorage.getItem("subs");
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(element1)));
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, fileName);
 
  }

  routeToSubscriptionDetails(subscription: ISubscription) {
    this.stateService.routeToSubscriptionDetails(subscription.id, subscription.productName);
 }

 routeToClientAppRegistrationDetails(subscription: ISubscription) {
  this.stateService.routeToClientAppRegistrationComponent(subscription.clientServicePrincipal, subscription.subscriptionName);
}


}
