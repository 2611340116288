import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HipManagementService } from '../../../services/hip-management/hip-management.service';
import { StateService } from '../../../services/state/State.service';
import { ICreateNewSubscription } from '../models/icreateNewSubscription';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../loading.service';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { Api } from 'src/app/models/api';


@Component({
  selector: 'app-request-subscription',
  templateUrl: './request-subscription.component.html',
  styleUrls: ['./request-subscription.component.css']
})
export class RequestSubscriptionComponent extends BaseComponent implements OnInit {

  createSubscription: ICreateNewSubscription;
  productName: string;
  subscribeForm: FormGroup;
  subscribeDisAllowed: boolean = false;
  subscriptions: Subscription[] = [];
  productEnvironmentId: string;
  cmdbId:string='';
  businessAreaCode:string;
  costCenter:string;
  famCode:string;
  apis: Api[];
  validationError: string = null;
  isClientAppMandatory:boolean = false;  

  displayedColumns: string[] = ['apiName','apiOwner', 'oauth'];
  isWait: boolean = false;
  mandatoryDetails:boolean = false;

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private hipManagementService: HipManagementService,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    public loader: LoadingService
  ) {
    super();

    this.subscribeForm = fb.group({
      environment: [{ value: null, disabled: true }, Validators.required],
      productName: [{ value: null, disabled: true }, Validators.required],
      email: [{ value: null, disabled: true }, Validators.required],
      costCenter: [{ value: null, disabled: true }],
      famCode: [{ value: null, disabled: true }, Validators.required],
      cmdb: [null, Validators.required],
      sponsorName: [{ value: null, disabled: true }, Validators.maxLength(50)],
      applicationName: [{ value: null, disabled: true }, Validators.maxLength(50)],
      businessAreaCode: [{ value: null, disabled: true }, Validators.required],
      usageNotes: [null, Validators.maxLength(1500)],
      productEnvironmentId: [null],
      userId: [null],
      name: [null],
      clientAppRegistrationAppId: ''
    });
  }

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.productName = params['productname'];
      this.productEnvironmentId = params['productenvironmentid'];

      this.createSubscription = {
        email: this.stateService.EmailAddress,
        environment: this.stateService.Environment,
        productName: this.productName,
        costCenter: null,
        famCode: '',
        cmdb: '',
        sponsorName: '',
        applicationName: '',
        businessAreaCode: '',
        usageNotes: '',
        productEnvironmentId: '',
        userId: '',
        name: '',
        clientAppRegistrationAppId: ''
      };
      this.subscribeForm.setValue(this.createSubscription);

      this.hipManagementService.GetApisByProductEnvironment(this.productEnvironmentId).subscribe(r => {
        this.apis = r.map(a => a);

        if (!this.apis.some(x => x.proxySecurityId))
        {
         this.subscribeForm.get('clientAppRegistrationAppId').disable();
        }
      });
    });    
  }
  loadDetailsById($event:any):void{    
      this.subscribeForm.controls['famCode'].setValue('');
      this.subscribeForm.controls['costCenter'].setValue('');
      this.subscribeForm.controls['businessAreaCode'].setValue('');
      this.subscribeForm.controls['sponsorName'].setValue('');
      this.subscribeForm.controls['applicationName'].setValue('');
      this.removeClientAppMandate();      
    if ($event.target.value != '') {
      this.subscribeDisAllowed = true;
      this.isWait = true;
      
        if ($event.target.value.toUpperCase().indexOf('APP-') !== 0) {
          $event.target.value = 'APP-' + $event.target.value;
          this.subscribeForm.controls['cmdb'].setValue($event.target.value);
        }      
    var cmdbDetails= this.hipManagementService.getDetailsByCmdbId($event.target.value).subscribe(r=>{
      if(r.familyCode!=null && r.costCenter!=null && r.businessArea!=null){
      this.subscribeForm.controls['famCode'].setValue(r.familyCode);
      this.subscribeForm.controls['costCenter'].setValue(r.costCenter);
      this.subscribeForm.controls['businessAreaCode'].setValue(r.businessArea);    
      this.subscribeForm.controls['sponsorName'].setValue(r.applicationOwner);
      this.subscribeForm.controls['applicationName'].setValue(r.applicationName);
      this.isWait = false;
      this.subscribeDisAllowed = false;
      this.mandatoryDetails = true;      
      if (r.businessArea.toUpperCase() == "CT") {
        this.setClientAppMandate();
      }
      else {
        this.removeClientAppMandate();
      }            
    }
    else{
      this.toaster.warning(`No details found inside the CMDB for this Application Id ${$event.target.value}`);
      this.isWait = false;
      this.subscribeDisAllowed = false;
      this.mandatoryDetails = false;
      this.removeClientAppMandate();      
    }
    },
    error => {
      this.validationError = error.error;
      this.isWait = false;
      this.subscribeDisAllowed = false;
      this.removeClientAppMandate();      
    });  
    }
  }
 
  setClientAppMandate(){
    this.isClientAppMandatory = true;
    this.subscribeForm.controls['clientAppRegistrationAppId'].setValidators([Validators.required]);
    this.subscribeForm.controls['clientAppRegistrationAppId'].updateValueAndValidity();
  }
  removeClientAppMandate(){
    this.isClientAppMandatory = false;
    this.subscribeForm.controls['clientAppRegistrationAppId'].clearValidators();
    this.subscribeForm.controls['clientAppRegistrationAppId'].updateValueAndValidity(); 
  }

  requestSubscription(): void {
    if (this.subscribeForm.valid && this.subscribeDisAllowed === false) {
      if (this.mandatoryDetails) {
        this.isWait = true;
        this.subscribeDisAllowed = true;      
        this.createSubscription = this.subscribeForm.getRawValue();
        this.createSubscription.productEnvironmentId = this.productEnvironmentId;
        this.createSubscription.userId = this.stateService.Account.localAccountId,
        this.createSubscription.name = this.stateService.Account.name,

        this.hipManagementService.CreateSubscription(this.createSubscription).subscribe(r => {
          this.stateService.routeToSubscriptionDetails(r.subscriptionId, this.productName);
        },
        error => {
          this.validationError = error.error;
          this.isWait = false;
          this.subscribeDisAllowed = false;
        });
      }
      else{
        this.toaster.warning(`Please enter a valid Application Id from the CMDB`);
      }      
    } else {      
      this.validateAllFormFields(this.subscribeForm);
    }
  }


  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
        if(control.status == 'INVALID') {
          // this.toaster.warning(`${field} is required`)
        }
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  getOAuthIndication(api: Api) {
    if (api.proxySecurityId){
      return "OAuth"
    }
    else {
      return "No OAuth"
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
