import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { ISubscription } from '../models/isubscription';
import { HipManagementService } from '../../../services/hip-management/hip-management.service';
import { StateService } from '../../../services/state/State.service';
import { Environments } from '../../../types/Environments';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../loading.service';


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent extends BaseComponent implements OnInit {
  loading$ = this.loader.loading$;
  environmentSelection: Environments;
  mySubscriptions$: Observable<ISubscription[]>;
  environmentName: string;
  displayedColumns: string[] = ['productName', 'usageNotes', 'costCenter', 'famCode', 'sponsorName', 'businessAreaCode', 'status','approvers', 'activatedDate'];

  // dataSource = new MatTableDataSource(this.mySubscriptions)

  constructor(
    private stateService: StateService,
    private hipManagementService: HipManagementService,
    public loader: LoadingService) {
    super();
  }

  ngOnInit() {

    this.stateService.setTitle("Subscriptions");

    this.stateService.environmentSelection$
    .pipe(takeUntil(this.destroyed))
    .subscribe(
      (next: Environments) => {
        this.environmentSelection = next;
        this.mySubscriptions$ = this.hipManagementService.GetSubscriptionsByEmail(this.environmentSelection);
      });

    // this.mySubscriptions$ = this.hipManagementService.GetSubscriptionsByEmail(this.environmentSelection);
  }

  routeToSubscriptionDetails(subscription: ISubscription) {
     this.stateService.routeToSubscriptionDetails(subscription.id, subscription.productName);
  }
}
