import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, ValidatorFn, Validators } from '@angular/forms';
import { IApiUsage } from '../../../models/IApiUsage';
import { ICreateApiUsage } from 'src/app/models/icreateApiUsage';
import { IApprovalRequest } from '../models/iapprovalRequest';
import { StateService } from 'src/app/services/state/State.service';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';
import { Environments } from 'src/app/types/Environments';
import { AppRole } from '../../subscriptions/appRole';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../loading.service';
import { IRejectSubscription } from '../../subscriptions/models/irejectSubscription';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RejectDialogComponent } from '../approval-details/dialog/reject-dialog.component';
import { Api } from 'src/app/models/api';


@Component({
  selector: 'app-approval-detail',
  templateUrl: './approval-detail.component.html',
  styleUrls: ['./approval-detail.component.css']
})
export class ApprovalDetailComponent extends BaseComponent implements OnInit {
  // the form model
  loading$ = this.loader.loading$;
  form: FormGroup;
  remarks: string;
  apiUsage: IApiUsage;
  apis: Api[];
  approveDisAllowed: boolean = false;
  createApiUsage: ICreateApiUsage;
  approvalRequest: IApprovalRequest;
  appRoles = [];
  subscriptionAttributes = [];
  hasRoles: boolean;
  hasSubscriptionAttributes: boolean;
  minimalSelect: number;
  environment: string;
  environmentSelection: Environments;
  validationError: string = null;
  isWait: boolean = false;
  errorMessage: string;
  appRolesAreFix: boolean = false;
  rolesCheckBoxesDisabled: boolean = false;


  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private hipManagementService: HipManagementService,
    private stateService: StateService,
    public loader: LoadingService) {
    super();

    this.apiUsage = this.stateService.apiUsage;
    this.appRoles = this.getAppRoles();
    this.subscriptionAttributes = this.getSubscriptionAttributes();

    if (!!this.appRoles && this.appRoles?.length > 0) {
      this.hasRoles = true;
      this.minimalSelect = 1;
      this.appRolesAreFix = this.appRoles.some(function (e) {
        return e.isTaken === true;
      });
    } else {
      this.hasRoles = false;
      this.minimalSelect = 0;
    }

    if (!!this.subscriptionAttributes && this.subscriptionAttributes?.length > 0) {
      this.hasSubscriptionAttributes = true;
    } else {
      this.hasSubscriptionAttributes = false;
    }

    if (!!this.apiUsage) {
      this.form = this.formBuilder.group({
        appRoles: new FormArray([], minSelectedCheckboxes(this.minimalSelect)),
        subscriptionAttributes: new FormArray([], Validators.minLength(1)),
        remark: new FormControl('')
      });

      this.addCheckboxes();
      this.addSubscriptionAttributes();
    }
  }

  ngOnInit() {
    if (!!this.apiUsage) {
      // this.form = this.formBuilder.group({
      //   appRoles: new FormArray([], minSelectedCheckboxes(this.minimalSelect)),
      //   subscriptionAttributes: new FormArray([], Validators.minLength(1)),
      //   remark: new FormControl('')
      // });

      // this.addCheckboxes();
      // this.addSubscriptionAttributes();

      this.stateService.environmentSelection$
        .pipe(takeUntil(this.destroyed))
        .subscribe(
          (next: Environments) => {
            if (this.environmentSelection === undefined) {
              this.environmentSelection = next;
              this.environment = this.environmentSelection;
            }
            else {
              this.stateService.routeToApprovals();
            }
          }
        );

      this.stateService.setTitle("Approval for API : " + this.apiUsage?.api.name);
    } else {
      this.stateService.routeToApprovals();
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
        if (control.status === 'INVALID') {
          // this.toaster.warning(`${field} is required`)
        }
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  getAppRoles() {
    return this.stateService.apiUsage.api?.apiEnvironments[0].proxySecurity?.appRoles;
  }

  getSubscriptionAttributes() {
    return this.stateService.apiUsage.api?.apiEnvironments[0].proxySecurity?.subscriptionAttributes;
  }

  private addCheckboxes() {

    if (this.appRoles?.length === 1) {
      this.rolesCheckBoxesDisabled = true;
      this.appRoles?.forEach(obj => {
        this.appRolesFormArray.push(new FormControl(this.appRoles.length === 1));
      });
    }
    else {
      this.appRoles?.forEach(obj => {
        const appRole = obj as AppRole;
        this.appRolesFormArray.push(new FormControl(appRole.isTaken));
      });
      this.rolesCheckBoxesDisabled = this.appRolesAreFix;
    }
  }



  private addSubscriptionAttributes() {
    this.subscriptionAttributes?.forEach(() => this.subscriptionAttributesFormArray.push(new FormControl()));
  }

  Cancel() {
    this.stateService.routeToApprovals();
  }

  Reject() {
    this.hipManagementService.GetApiNames(this.apiUsage.subscription.id).subscribe(r => {
      this.apis = r;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        subscription: this.apiUsage.subscription.subscriptionName,
        apiNames: this.apis.map(function(a) { return a.name; })
      };

      const dialogRef = this.dialog.open(RejectDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(
        data => {


          const rejectSubscription: IRejectSubscription = {
            SubscriptionId: this.apiUsage.subscription.id,
            Reason: data.reasonReject,
            RejectedBy: this.stateService.EmailAddress };

          this.hipManagementService.rejectSubscription(rejectSubscription).subscribe(r => {
            this.stateService.routeToApprovals();
          });
        }
      );

    });



  }

  Approve() {
    if (this.form.valid && this.approveDisAllowed === false) {
      this.approveDisAllowed = true;
      this.isWait = true;

      const selectedAppRoleIds = this.form.value.appRoles
        .map((v, i) => v ? this.appRoles[i].id : null)
        .filter(v => v !== null);

      const subscriptionAttributesToReturn = {};
      this.form.value.subscriptionAttributes
        .map((v, i) => {
          subscriptionAttributesToReturn[this.subscriptionAttributes[i].id] = v;
        });

      this.hipManagementService.CreateApiUsage(this.apiUsage, this.remarks, selectedAppRoleIds, subscriptionAttributesToReturn)
        .subscribe(r => {
          this.stateService.routeToApprovals();
        },
          error => {
            this.validationError = error.error;
            this.isWait = false;
            // this.subscribeDisAllowed = false;
          });
    } else {
      this.isWait = false;
      this.validateAllFormFields(this.form);
    }
  }

  get appRolesFormArray() {
    return this.form.controls.appRoles as FormArray;
  }

  get subscriptionAttributesFormArray() {
    return this.form.controls.subscriptionAttributes as FormArray;
  }
}


function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);

    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}
