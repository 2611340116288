import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/core/base-classes/base-component';
import { HipManagementService } from 'src/app/services/hip-management/hip-management.service';
import { StateService } from 'src/app/services/state/State.service';
import { Environments } from 'src/app/types/Environments';
import { LoadingService } from '../loading.service';
import { IAppRoleAssignment } from 'src/app/ui/appRegistrations/models/iappRoleAssignment';



@Component({
    selector: 'app-clientappregistration',
    templateUrl: './clientappregistration.component.html',
    styleUrls: ['./clientappregistration.component.scss']
  })

  export class AppRegistrationComponent extends BaseComponent implements OnInit {

    loading$ = this.loader.loading$;
    environmentSelection: Environments;
    appRoleAssignment$: Observable<IAppRoleAssignment[]>;
    clientServicePrincipalId: string;
    displayedColumns: string[] = [ 'principalId', 'principalDisplayName', 'resourceId', 'resourceDisplayName',
    'appRoleId', 'roleName'];


    constructor(
        private stateService: StateService,
        private hipManagementService: HipManagementService,
        private route: ActivatedRoute,
        public loader: LoadingService) {
        super();

        this.route.params.subscribe((params) => {
          this.clientServicePrincipalId = params['clientServicePrincipalId'];
        });
      }


      ngOnInit(): void {
        this.stateService.setTitle('Client App Registration Details for Subscription : ' + this.stateService.subscriptionName);

        this.appRoleAssignment$ = this.hipManagementService.GetAssignedAppRoles(this.clientServicePrincipalId);


    }
  }
