import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HipManagementService } from '../../../services/hip-management/hip-management.service';
import { StateService } from '../../../services/state/State.service';
import { ICreateApi } from '../models/icreateApi';


@Component({
    selector: 'app-upsert-api',
    templateUrl: './upsert-api.component.html',
    styleUrls: ['./upsert-api.component.scss']
})
export class UpsertApiComponent implements OnInit {

    buttonDisAllowed: boolean = false;
    apiForm: FormGroup;
    creatNewApi: ICreateApi;
    emails: string[] = [];

    constructor(fb: FormBuilder,
                private hipManagementService: HipManagementService,
    ) {

        this.apiForm = fb.group({
            environment: [null, Validators.required],
            apiName: [null, Validators.required],
            costCenter: [null, Validators.required],
            famCode: [null, Validators.required],
            sponsorName: [null, Validators.maxLength(50)],
            businessAreaCode: [null, Validators.required],
            urlSuffix: [null, Validators.maxLength(100)],
            emails: [null, Validators.email],
            approvers: [null]
        });
    }

    ngOnInit(): void {
        this.creatNewApi = {
            environment: '',
            apiName: '',
            costCenter: null,
            famCode: '',
            sponsorName: '',
            businessAreaCode: '',
            urlSuffix: '',
            approvers: '',
            emails: []
          };

        // this.approvers = '';
        this.apiForm.setValue(this.creatNewApi);
    }

    createNewApi(): void {
        if (this.apiForm.valid && this.buttonDisAllowed === false) {
            this.buttonDisAllowed = true;
            this.creatNewApi = this.apiForm.getRawValue();
            this.emails = this.apiForm.get("approvers").value.split(';');
            this.creatNewApi.emails = this.emails;
            this.hipManagementService.CreateApi(this.creatNewApi).subscribe(r => {
                // toDo
                this.buttonDisAllowed = false;

                this.creatNewApi = {
                    environment: '',
                    apiName: '',
                    costCenter: null,
                    famCode: '',
                    sponsorName: '',
                    businessAreaCode: '',
                    urlSuffix: '',
                    approvers: '',
                    emails: []
                };
                // this.approvers = '';
                this.apiForm.setValue(this.creatNewApi);
            });
        }

    }
}
