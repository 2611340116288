import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Api } from 'src/app/models/api';
import { Product } from 'src/app/models/product';
import { StateService } from 'src/app/services/state/State.service';
import { Environments } from 'src/app/types/Environments';


@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent implements OnInit {
  environmentSelection: Environments;

  tiles = [
    {
      text: "One",
      cols: 2,
      rows: 2,
      color: "lightblue",
      header: "Digital Asset Management",
      businessUnit: "SS",
      apis: ["abc", "Notification API"]
    },
    {
      text:
        "Two for thee and thee for two...longer than this. lines and more yes we can. maybe we can..if very long i'm curious yes now..if very long i'm curious yes now  ",
      cols: 2,
      rows: 2,
      color: "lightgreen",
      header: "PTD API Product",
      businessUnit: "IT",
      apis: ["ABC API", "YXZ API", "123 API"]
    },
    {
      text: "Three",
      cols: 2,
      rows: 2,
      color: "lightpink",
      header: "Group Codes",
      businessUnit: "SS"
    },
    { text: "Four", cols: 2, rows: 2, color: "#DDBDF1" }
  ];

 @Input() product: Product;

  @ViewChild("box", { static: false }) box: ElementRef;
  columns: number = 2;

  constructor(  private stateService: StateService) { }

  ngOnInit(): void {
    this.stateService.environmentSelection$.subscribe(
      (next: Environments) => {
        this.environmentSelection = next;
      }
    );
  }

  setColumns() {
    var colums = Math.floor(this.box.nativeElement.clientWidth / 100);
    if (colums < 2) {
      colums = 2;
    }
    this.columns = colums;
  }


  routeToRequestSubscription(product: Product): void {
    this.stateService.routeToRequestSubscription(product.productEnvironmentId, product.name, this.environmentSelection);
  }

  routeToSwagger(api: Api): void {
    this.stateService.routeToSwagger(api);
  }
}
