<mat-grid-list cols="2" rowHeight="2:1" gutterSize="5px">
  <mat-grid-tile colspan="2">
    <mat-card>   
    <div class="example-container mat-elevation-z8">
      
      <table mat-table *ngIf="apis else loading" class="mat-elevation-z8" [dataSource]="apis">

        <!-- Api Name Column -->
        <ng-container matColumnDef="apiName">
          <th mat-header-cell *matHeaderCellDef>API name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

      
          <!-- BusinessAreaCode Column -->
          <ng-container matColumnDef="businessAreaCode">
            <th mat-header-cell *matHeaderCellDef>BusinessAreaCode</th>
            <td mat-cell *matCellDef="let element"> {{element.businessAreaCode}} </td>
          </ng-container>
          
          <!-- CostCenter Column -->
          <ng-container matColumnDef="costCenter">
            <th mat-header-cell *matHeaderCellDef>CostCenter</th>
            <td mat-cell *matCellDef="let element"> {{element.costCenter}} </td>
          </ng-container>
          
          <!-- FamCode Column -->
          <ng-container matColumnDef="famCode">
            <th mat-header-cell *matHeaderCellDef>FamCode</th>
            <td mat-cell *matCellDef="let element"> {{element.famCode}} </td>
          </ng-container>
          
          <!-- SponsorName Column -->
          <ng-container matColumnDef="sponsorName">
            <th mat-header-cell *matHeaderCellDef>SponsorName</th>
            <td mat-cell *matCellDef="let element"> {{element.sponsorName}} </td>
          </ng-container>

           <!-- RequiresManualApproval Column -->
           <ng-container matColumnDef="requiresManualApproval">
            <th mat-header-cell *matHeaderCellDef>RequiresManualApproval</th>
            <td mat-cell *matCellDef="let element"> {{element.requiresManualApproval}} </td>
          </ng-container>

          <!-- UrlSuffix Column -->
          <ng-container matColumnDef="urlSuffix">
            <th mat-header-cell *matHeaderCellDef>UrlSuffix</th>
            <td mat-cell *matCellDef="let element"> {{element.urlSuffix}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell  *matHeaderCellDef > Actions </mat-header-cell>
            <mat-cell *matCellDef="let element" >
                 <button mat-button (click)="updateApi(element)">Update</button>
            </mat-cell>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="mat-display-1" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="testApi()"></tr>

      </table>
    </div>

  </mat-card>
  </mat-grid-tile>
</mat-grid-list>
<ng-template #loading>
  <mat-label>Loading Api</mat-label>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>