import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from "rxjs";
@Injectable()
export abstract class BaseComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  destroyed = new Subject();
  constructor() { }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroyed.next();
    this.destroyed.complete();
  }
}
