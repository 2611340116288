<mat-grid-list cols="1" rowHeight="fit" gutterSize="5px">
   

    <mat-grid-tile>
        <mat-card>
            <mat-card-header>
                <mat-card-title>You are about to create/update an API
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form fxLayout="column" [formGroup]="apiForm">
                    <!-- environment  -->
                    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="Environment" matNativeControl required
                            formControlName="environment" matTooltip="Select environment">
                            <mat-option value="DEV">DEV</mat-option>
                            <mat-option value="TST">TST</mat-option>
                            <mat-option value="PRD">PRD</mat-option>                          
                        </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- name -->
                    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="ApiName" formControlName="apiName" required>
                        </mat-form-field>
                    </div>

                    <!-- businessAreaCode -->
                    <div class="input-row">
                        <mat-form-field fxFlex>                           
                            <mat-select placeholder="Business Area Code" matNativeControl required
                                formControlName="businessAreaCode" matTooltip="Select business area code">
                                <mat-option value="IT">IT</mat-option>
                                <mat-option value="CT">CT</mat-option>
                                <mat-option value="VT">VT</mat-option>
                                <mat-option value="PT">PT</mat-option>
                                <mat-option value="CO">CO</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- costCenter -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Cost Center" formControlName="costCenter"
                                matTooltip="Enter the cost center were to invoice consumptions" required>
                        </mat-form-field>
                    </div>

                    <!-- famCode -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="FAM Code" formControlName="famCode"
                                matTooltip="Enter your internal division code" required>
                        </mat-form-field>
                    </div>

                    <!-- sponsorName -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Sponsor name" formControlName="sponsorName"
                                matTooltip="Enter the sponsor name">
                        </mat-form-field>
                    </div>

                       <!-- urlSuffix -->
                       <div class="input-row">
                        <mat-form-field fxFlex>
                            <input matInput type="text" placeholder="Url suffix" formControlName="urlSuffix"
                                matTooltip="Enter the url suffix">
                        </mat-form-field>
                    </div>

                   
                    
                    <!-- approvers -->
                    <div class="input-row">
                        <mat-form-field fxFlex>
                            <mat-label>Approvers (emails - separate with ';' )</mat-label>                            
                           
                            <textarea matInput placeholder="Email(s) approvers" formControlName="approvers"
                                matTooltip="Enter any email for apporvers separated by ';'" matInput placeholder=""></textarea>
                        </mat-form-field>
                    </div>


                </form>
            </mat-card-content>
            <mat-card-actions>
                <button mat-flat-button color="primary"  [disabled]="buttonDisAllowed"
                (click)="createNewApi()">Create Api</button>
            </mat-card-actions>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>

<ng-template #loading>
    <mat-label>Loading details</mat-label>
    <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>